import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'

const defaultButton = props => <button {...props}>{props.children}</button>

export default class Pagination extends React.Component {
  constructor(props) {
    super()

    this.changePage = this.changePage.bind(this)

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    }
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      })
    }

    this.changePage(nextProps.page + 1)
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages)
  }

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  changePage(page) {
    const activePage = this.props.page + 1

    if (page === activePage) {
      return
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages)

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    })

    this.props.onPageChange(page - 1)
  }

  render() {
    const {
      PageButtonComponent = defaultButton,
      showPageSizeOptions,
      onPageSizeChange,
      pageSize,
      data,
      count,
      pageSizeOptions
    } = this.props
    const { visiblePages } = this.state

    const activePage = this.props.page + 1;
    const pageStart = this.props.pageSize * this.props.page + 1;
    const pageEnd = this.props.pageSize * (this.props.page + 1);

    return (
      <div className="p-3">
        <Row>
          <Col xs="12" lg="6">
            <select
              className="select-page"
              onChange={e => onPageSizeChange(Number(e.target.value))}
              value={pageSize}
            >
            {pageSizeOptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span className="select-page-text">
            Showing {pageStart} - {pageEnd <= count ? pageEnd : count} of {count}
          </span>
          </Col>
          <Col xs="12" lg="6">
              <Row className="justify-content-end">
                <Col xs="auto">
                  <PageButtonComponent
                    className="switchPageButton"
                    onClick={() => {
                      if (activePage === 1) return
                      this.changePage(1)
                    }}
                    disabled={activePage === 1}
                  >
                    <i style={{ fontSize: 8 }} className="flaticon2-fast-back" />
                  </PageButtonComponent>
                  <PageButtonComponent
                    className="switchPageButton"
                    onClick={() => {
                      if (activePage === 1) return
                      this.changePage(activePage - 1)
                    }}
                    disabled={activePage === 1}
                  >
                    <i style={{ fontSize: 8 }} className="flaticon2-back" />
                  </PageButtonComponent>
                </Col>
                <Col xs="auto">
                  {visiblePages.map((page, index, array) => {
                    return (
                      <PageButtonComponent
                        key={page}
                        className={
                          activePage === page
                            ? 'Table__pageButton Table__pageButton--active'
                            : 'Table__pageButton'
                        }
                        onClick={this.changePage.bind(null, page)}
                      >
                        {array[index - 1] + 2 < page ? `${page}` : page}
                      </PageButtonComponent>
                    )
                  })}
                </Col>
                <Col xs="auto">
                  <PageButtonComponent
                    className="switchPageButton"
                    onClick={() => {
                      if (activePage === this.props.pages) return
                      this.changePage(activePage + 1)
                    }}
                    disabled={activePage === this.props.pages}
                  >
                    <i style={{ fontSize: 8 }} className="flaticon2-next" />
                  </PageButtonComponent>
                  <PageButtonComponent
                    className="switchPageButton"
                    onClick={() => {
                      if (activePage === this.props.pages) return
                      this.changePage(this.props.pages)
                    }}
                    disabled={activePage === this.props.pages}
                  >
                    <i style={{ fontSize: 8 }} className="flaticon2-fast-next" />
                  </PageButtonComponent>
                </Col>
              </Row>
          </Col>
        </Row>
      </div>
    )
  }
}
