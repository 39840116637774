import React from "react"

export default class CardTabToolbar extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      heading: props.heading,
      subHeading: props.subHeading,
      avatar: props.avatar
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      heading,
      subHeading,
      avatar
    } = this.state

    // if(!values){
    //   return null
    // }

    return (
      <>
        <div className="kt-portlet__head-toolbar">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-right" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#all" role="tab" aria-selected="true">
                 All
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#needs-attention" role="tab" aria-selected="false">
                Needs Attention
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#running" role="tab" aria-selected="false">
                 Running
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#completed" role="tab" aria-selected="false">
                 Completed
              </a>
            </li>
          </ul>
        </div>
      </>
    )
  }
}
