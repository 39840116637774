import React from "react"
import moment from 'moment'

import BaseTable from "./BaseTable"
import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import { formatTableID } from '../../../utils/Formatter';

export default class PhoneCallsTable extends React.Component {
  _getColumns() {
    let columns = [
      {
        Header: 'Call ID',
        id: 'id',
        accessor: call => formatTableID(call.id * 49)
      },
      {
        Header: 'Date, Time',
        id: 'created_at',
        accessor: vehicle => moment(vehicle.created_at)
          .calendar(null, {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'DD/MM/YYYY, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'DD/MM/YYYY, HH:mm',
            sameElse: 'DD/MM/YYYY, HH:mm'
          })
      },
      {
        Header: 'Customer',
        id: 'customer.user.first_name',
        accessor: call => {
          if (call.customer && call.customer.user) {
            return `${call.customer.user.first_name} ${call.customer.user.last_name}`
          }
          return "";
        }
      },
      {
        Header: 'Phone',
        id: 'customer.user.phone',
        accessor: call => {
          if (call.customer && call.customer.user.phone_number) {
            return call.customer.user.phone_number
          }
          return "";
        }
      },
      {
        Header: 'Email',
        id: 'customer.user.email',
        accessor: call => {
          if (call.customer && call.customer.user.email) {
            return call.customer.user.email
          }
          return "";
        }
      },
      {
        Header: 'Vehicle',
        id: "vehicle.model.name",
        accessor: call => {
          return `${call.vehicle.model.manufacturer.name} ${call.vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'vehicle.registration_number'
      }
    ]

    return columns
  }

  render() {
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          searchButton={true}
          endpoint={window.Api.Calls}
          columns={columns}
          placeholder="Search"
          showLabel={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </div>
    )
  }
}

PhoneCallsTable.defaultProps = {}
