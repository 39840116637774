import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { pdf } from '@react-pdf/renderer';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';

import FinanceApplicationPDF from '../pdf/FinanceApplicationPDF';
import './FinanceApplication.css'

export default class FinanceApplicationModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show: props.show,
      laoding: true,
    }
    console.log(props.data)
  }

  componentDidMount() {
    this.loadPDF({});
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  loadPDF(data) {
    if(data){
      try {
        pdf(<FinanceApplicationPDF data={this.props.data} />)
          .toBlob()
          .then(blob => {
            const url = URL.createObjectURL(blob)
            this.setState({ 
              url: url,
              blobData: blob
            })
          })
      } catch (error) {
        console.error(error);        
      }
    }
    return;
  }

  onDocumentLoad = ({numPages}) => {
    this.setState({totalPages: numPages})
  }

  // priceConvertor = vehicle => vehicle.price ? `€${vehicle.price / 100}` : "-"

  render() {
    let { show, blobData, url, totalPages } = this.state
  
    return (
      <Modal
        className="finance-application-model"
        centered
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg">
        <Modal.Body>
          <div className="row px-5 pt-3">
            <div className="col-lg-3">
              <button 
                href="#"
                className={`btn block btn-block btn-primary btn-sm btn-bold`}
                onClick={() => {
                  window.open(url);
                }}
              >
                Print
              </button>
            </div>
          </div>
          <div>
            {blobData && (
              <>
                <Document 
                  wrap={false}
                  file={blobData}
                  options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                  }}
                  onLoadSuccess={this.onDocumentLoad.bind(this)}>
                  { [...Array(totalPages)].map( (page, i) => (
                    <>
                      <Page 
                        pageNumber={i+1} 
                        renderTextLayer={true} 
                        renderAnnotationLayer={true} 
                      />
                      <div class="dropdown-divider"></div>
                    </>
                  ))}
                </Document>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
