import React from "react"
import { withRouter } from "react-router-dom";
import { ROUTES } from '../../constants/Routes';
class SubHeader extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentDidMount(){
    this._updateActive()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _updateActive() {
    switch(this.props.location.pathname){
      case ROUTES.Dashboard:
        this._setActive("Dashboard")
        break;

      case ROUTES.CallBackRequests:
        this._setActive("Call Back Requests")
        break;

      case ROUTES.TestDriveRequests:
        this._setActive("Test Drive Requests")
        break;

      case ROUTES.WebsiteLeads:
        this._setActive("Website Leads")
        break;

      case ROUTES.Chats:
        this._setActive("Chats & WhatsApps")
        break;

      case ROUTES.PhoneCalls:
        this._setActive("Phone Calls")
        break;

      case ROUTES.FinanceApplications:
        this._setActive("Finance Applications")
        break;
      
      case ROUTES.MapLeads:
        this._setActive("Map Leads")
        break;

      case ROUTES.Shortlists:
        this._setActive("Prospects")
        break;

      case ROUTES.Listings:
        this._setActive("Listings")
        break;

      case ROUTES.Dealership:
        this._setActive("Dealership")
        break;

      case ROUTES.Staff:
        this._setActive("Staff")
        break;

      default:
        this._setActive("")
        // do nothing
        break;
    }
  }

  _setActive(title){
    this.setState({ title })
  }

  render() {
    let { title } = this.state

    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-subheader__main">
          <h3 className="kt-subheader__title">{ title }</h3>
        </div>
        <div className="kt-subheader__toolbar">

        </div>
      </div>
    )
  }
}

export default withRouter(SubHeader);
