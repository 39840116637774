import React from 'react';

import { Portal } from 'react-portal';

import Chat from '../chat/Chat';

import "./ChatPortal.css"

import CloseIcon from '../../../assets/icons/close.svg'

const ChatPortal = ({ conversationId, user, dealer, vehicle, hideChat, openFinanceApp }) => {
  return (
    <Portal node={document && document.getElementById('chat_portal')}>
      <div
        className="modal fade- modal-sticky-bottom-right show"
        role="dialog"
        data-backdrop="false"
        aria-modal="true"
        style={{ display: "block" }}
      >
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="kt-chat">
                  <div className="kt-portlet kt-portlet--last">
                    <div className="kt-portlet__head chat-head-holder">
                      <div className="kt-chat__head chat-head-container">
                        <div className="kt-chat__left">
                          <h2 className="chat-header-name">{user.first_name} {user.last_name}</h2>
                          
                          <h6 className="chat-header-car-info">{`${vehicle.model.manufacturer.name} ${vehicle.model.name} - ${vehicle.registration_number}`}</h6>
                          
                          { user.email &&
                            <h6 className="chat-header-email"> 
                              Email: <a className="message-link" href={`mailto:${user.email}`}>{`${user.email}`}</a>
                            </h6>
                          }
                          
                          { user.phone_number &&
                            <h6 className="chat-header-phone">
                              Phone: <a className="message-link" href={`tel:${user.phone_country_code || ''}${user.phone_number}`}>
                                {`${user.phone_country_code || ''}${user.phone_number}`}
                              </a>
                            </h6>
                          }
                        </div>
                        <div className="kt-chat__right chat-button-holder">
                          <button
                            type="button"
                            className="btn btn-clean btn-sm btn-icon"
                            onClick={hideChat}
                          >
                            <img src={CloseIcon} alt="close icon"/>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="kt-portlet__body">
                      <Chat 
                        openFinanceApp={openFinanceApp}
                        vehicle_id={vehicle.id}
                        user={user}
                        dealer={dealer}
                        conversation={{id: conversationId}}
                      />
                    </div>
                </div>
              </div>
              </div>
          </div>
      </div>
    </Portal>
  )
}

export default ChatPortal;


