import React from "react"
import './StatCard.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class StatCard extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      value: props.value,
      size: props.size
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      value,
      size,
      children,
      info,
    } = this.state

    return (
      <div className={ size } onClick={this.props.onClick} className={[size, 'stat-card', this.props.onClick ? 'link' : ''].join(' ')}>
        <div className="kt-portlet stat-card">
          <div className="kt-portlet__body  kt-portlet__body--fit">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <h4 className="kt-widget24__title">
                    { title }
                  </h4>
                </div>
                {info && (
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 500 }}
                    overlay={(
                      <Tooltip id="button-tooltip">
                        {info}
                      </Tooltip>
                    )}
                  >
                    <img 
                      src={require('../../../assets/icons/info.svg')}
                      // data-container="body" 
                      // data-toggle="kt-popover" 
                      // data-placement="bottom" 
                      // data-trigger="hover"
                      // data-content={info}
                    />
                  </OverlayTrigger>
                )}
              </div>
              <div className="kt-widget24__details">
                <span className="kt-widget24__stats kt-font-brand">
                   { value }
                </span>
              </div>
              { children }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
