import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"

import ConversationActionCell from "./cells/ConversationActionCell"
import ConversationStatusCell from "./cells/ConversationStatusCell"

import ChatPortal from "../chat/ChatPortal"

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'

import FinanceApplicationModal from '../modal/FinanceApplication'

import { formatTableID } from '../../../utils/Formatter';

export default class FinanceApplicationsTable extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      showApplication: props.showApplication,
      selectedApplication: null,
      showApplicationPrint: false,
      showChat: false
    }
  }

  async showChat(application){
    this.setState({
      showChat: true,
      selectedApplication: application
    })
  }
  hideChat() {
    this.setState({
      showChat: false,
      selectedApplication: null
    });
  }

  openFinanceApp(finance_id) {
    Backend.getFinanceApplication(finance_id)
    .then((data) => {
      this.hideChat();
      
      data.vehicle.dealer = this.props.dealer
      this.setState({ 
        selectedApplication: data,
        showApplicationPrint: true 
      })
    }).catch(e => alert(e))
  }

  _renderChat(){
    let {
      showChat,
      selectedApplication
    } = this.state;

    if(!showChat){
      return null
    }

    const user = selectedApplication.customer.user;
    const dealer = this.props.dealer;
    const vehicle = selectedApplication.vehicle;

    return (
      <ChatPortal
        openFinanceApp={ this.openFinanceApp.bind(this) }
        conversationId={selectedApplication.conversation_id}
        user={user} dealer={dealer} vehicle={vehicle}
        hideChat={() => this.hideChat()}
      />
    )
  }

  _getColumns() {
    let columns = [
      {
        Header: 'FA ID',
        id: 'id',
        accessor: application => formatTableID(application.id * 49)
      },
      {
        Header: 'Customer',
        id: "customer.user.first_name",
        accessor: conversation => {
          return `${conversation.customer.user.first_name} ${conversation.customer.user.last_name}`
        }
      },
      {
        Header: 'Phone',
        id: 'customer.user.phone',
        accessor: conversation => {
          if (conversation.customer && conversation.customer.user.phone_number) {
            return conversation.customer.user.phone_number
          }
          return "";
        }
      },
      {
        Header: 'Email',
        id: 'customer.user.email',
        accessor: conversation => {
          if (conversation.customer && conversation.customer.user.email) {
            return conversation.customer.user.email
          }
          return "";
        }
      },
      {
        Header: 'Vehicle',
        id: "vehicle.model.name",
        accessor: conversation => {
          return `${conversation.vehicle.model.manufacturer.name} ${conversation.vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'vehicle.registration_number'
      },
      {
        Header: 'Last Message',
        id: "conversation.last_message.created_at",
        accessor: conversation => {
          return conversation.created_at ? moment(conversation.created_at).calendar(null, {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'DD/MM/YYYY, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'DD/MM/YYYY, HH:mm',
            sameElse: 'DD/MM/YYYY, HH:mm'
          }) : "-"
        }
      },
      {
        id: "status",
        Header: 'Status',
        Cell: rowInfo => {
          return (
            <ConversationStatusCell
              status={rowInfo.original.status || 'open'}
              onEdited={status => {
                const { id } = rowInfo.original;
                Backend.changeApplicationStatus(id, status).then(status => {
                  console.log({status});
                });
              }}
            />
          )
        }
      },
      {
        id: "application",
        Header: 'Application',
        sortable: false,
        Cell: rowInfo => {
          return (
            <button 
              className={`btn btn-block btn-secondary btn-sm`}
              onClick={e => {
                e.preventDefault()
                rowInfo.original.vehicle.dealer = this.props.dealer
                this.setState({ selectedApplication: rowInfo.original, showApplicationPrint: true })
              }}
            >
              View
            </button>
          )
        }
      },
      {
        id: "actions",
        Header: 'Actions',
        sortable: false,
        Cell: rowInfo => {
          return (
            <ConversationActionCell
              conversation={rowInfo.original}
              onChatClick={conversation => this.showChat(rowInfo.original)}
            />
          )
        }
      }
    ]

    if(!this.props.showApplication) {
      const index = columns.findIndex(column => column.id === 'application')
      columns.splice(index, 1)
    }

    return columns
  }

  render() {
    const { selectedApplication, showApplication, showApplicationPrint } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          searchButton={true}
          endpoint={`${window.Api.FinanceApplications}/all`}
          columns={columns}
          placeholder="Search"
          showLabel={false}
          defaultSorted={[
            {
              id: "conversation.last_message.created_at",
              desc: true
            }
          ]}
        />

        { showApplicationPrint && 
          <FinanceApplicationModal 
            data={selectedApplication}
            show={showApplicationPrint} 
            onHide={() => {
              this.setState({ 
                selectedApplication: null,
                showApplicationPrint: false
              })
            }}
          />
        }

        { this._renderChat() }
      </div>
    )
  }
}

FinanceApplicationsTable.defaultProps = {
  dealerMode: true
}
