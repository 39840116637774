import React, { PureComponent } from "react"

import CardItem from './CardItem'
import CardTabToolbar from './CardTabToolbar'

export default class Card extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      title: props.title,
      type: props.type,
      items: props.items
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      title,
      type,
      items
    } = this.state

    // if(!values){
    //   return null
    // }

    return (
      <div className={this.props.className}>
				<div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
					<div className="kt-portlet__head">
						<div className="kt-portlet__head-label">
							<h3 className="kt-portlet__head-title">
								{ title }
							</h3>
						</div>
            { this.props.renderToolbar() }
					</div>
					<div className="kt-portlet__body">
						<div className="tab-content">
							<div className="tab-pane active" id="kt_widget4_tab1_content">
								<div className="kt-widget4">

                  { this.props.children }

								</div>
							</div>
						</div>
					</div>
				</div>
      </div>
    )
  }
}

Card.defaultProps = {
  className: "col-xl-6",
  renderToolbar: () => {}
}
