import React from "react"

export default class ChatPortal extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  render() {

    return (
      <div id="kt_chat_portal">
      </div>
    )
  }
}
