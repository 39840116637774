import React from "react"

import Card from './components/cards/Card'
import ConversationsTable from './components/tables/ConversationsTable'

import Backend from '../utils/Backend'

export default class Messages extends React.Component {
  constructor(props){
    super(props)

    document.title = "Sweep Dealer Dashboard - Chats & WhatsApps";


    if(props.location.query){
      this.initial_conversation_id = props.location.query.conversation_id
    }

    this.state = {
      loading: false,
      searchTerm: '',
      statusFilters: [],
    }
    this.conversationsTable = React.createRef();
  }

  componentDidMount(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let initialConversationId = params.get('conversation_id');

    if(initialConversationId){
      this._loadConversation(initialConversationId)
    }
  }

  _loadConversation(conversationId){
    Backend.getConversation(conversationId)
      .then(conversation => {
        this.conversationsTable.current.showChat(conversation)
      })
      .catch(() => {

      })
  }


  render() {
    let { loading } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
    
          <Card
            title="Chats & WhatsApps"
            className="col-xl-12"
          >
            <ConversationsTable
              ref={this.conversationsTable}
            />
          </Card>
        </div>
      </div>
    )
  }
}

Messages.defaultProps = {
  dealerMode: true
}
