import React from "react"
import Toggle from 'react-toggle'

import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import Div from './components/layouts/Div'
import { SpinnerContext } from '../components/Spinner';
import "react-toggle/style.css"

export default class Settings extends React.Component {
  static contextType = SpinnerContext;

  constructor(props){
    super(props)

    document.title = "Sweep Dealer Dashboard - Dealership";

    this.state = {
      dealer: null,
      loading: true,
      isAdmin: AuthManager.currentUser.role === "admin"
    }
  }

  componentDidMount(){
    this._loadDealer()
  }

  _loadDealer(){
    this.context.toggleSpinner(true)
    Backend.getDealer(AuthManager.currentDealer.id)
    .then(dealer => {
      this.setState({
        dealer,
        loading: false
      });
      this.context.toggleSpinner(false)
    })
    .catch(e => {
      this.context.toggleSpinner(true)
    }) 
  }

  _goToDealers(){
    this.props.history.push(`/dealers`)
  }

  _isValid(data){
    let error = null;

    if (!data.name){
      error = "You must include a name"
    }

    if(error){
      alert(error);
      return false;
    }
    return true
  }

  _handleUpdate() {
    let {
      dealer
    } = this.state

    if(!this._isValid(dealer)){
      return
    }

    this._update()
  }

  _update() {
    let {
      dealer
    } = this.state

    this.setState({loading: true})
    Backend.editDealer(dealer)
    .then(dealer => {
      this.setState({
        dealer,
        loading: false
      })
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  render() {
    let {
      dealer,
      isAdmin,
      loading
    } = this.state

    if(loading){
      return null
    }

    return (
      <div className="row">
				<div className="col-md-6">
					<Div className="kt-portlet" disabled={loading}>
						<div className="kt-portlet__head">
							<div className="kt-portlet__head-label">
								<h3 className="kt-portlet__head-title">
                Dealer
								</h3>
							</div>
						</div>


						<form className="kt-form">
							<div key={dealer.staff.length} className="kt-portlet__body">
								<h5 className="kt-section__title">Details:</h5><br/>

								<div className="form-group">
									<label>Name</label>
									<input type="text" className="form-control"
                    value={dealer.name}
                    disabled={!isAdmin}
                    onChange={e => {
                      dealer.name = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Name"
                  />
								</div>

                <div className="form-group">
									<label>Phone</label>
									<input type="text" className="form-control"
                    value={dealer.phone}
                    disabled={!isAdmin}
                    onChange={e => {
                      dealer.phone = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Phone"
                  />
								</div>

                <div className="form-group">
									<label>Email Domain</label>
									<input type="text" className="form-control"
                    value={dealer.email_domain}
                    disabled={!isAdmin}
                    onChange={e => {
                      dealer.domain = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Email Domain"
                  />
								</div>

                <div className="form-group">
									<label>Website</label>
									<input type="text" className="form-control"
                    value={dealer.website}
                    disabled={!isAdmin}
                    onChange={e => {
                      dealer.website = e.target.value
                      this.setState({ dealer })
                    }}
                    placeholder="Website"
                  />
								</div>

                {/* <div className="form-group">
									<label style={{display: 'flex', alignItems: 'center'}}>
                    <Toggle
                      defaultChecked={dealer.whatsapp_enabled}
                      icons={false}
                      onChange={(e) => {
                        dealer.whatsapp_enabled = e.target.checked
                        if(!dealer.whatsapp_enabled) {
                          dealer.whatsapp_number = ''
                        }
                        this.setState({ dealer })
                      }} 
                    />
                    <span style={{ marginLeft: '10px'}}>Whatsapp Enabled ?</span>
                  </label>                  
								</div> */}

                { dealer.whatsapp_enabled && (
                  <div className="form-group">
                    <label>Whatsapp Number</label>
                    <input type="text" className="form-control"
                      value={dealer.whatsapp_number}
                      disabled={!isAdmin}
                      onChange={e => {
                        dealer.whatsapp_number = e.target.value
                        this.setState({ dealer })
                      }}
                      placeholder="e.g. 0879999999"
                      disabled={!dealer.whatsapp_enabled}
                    />
                  </div>
                )}

							</div>
              { isAdmin &&
  							<div className="kt-portlet__foot">
  								<div className="kt-form__actions">
  									<button type="reset" onClick={() => this._handleUpdate()} className="btn btn-primary">
                      Update
                    </button>
  								</div>
  							</div>
              }
						</form>
					</Div>
				</div>
			</div>
    )
  }
}
