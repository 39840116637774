import React from "react"

import Card from './components/cards/Card'
import WebsiteLeadsTable from './components/tables/WebsiteLeadsTable'
import SimpleCard from './components/cards/SimpleCard'
import {ReactComponent as InfoIcon} from '../assets/icons/info.svg';
import './MapLeads.css'
export default class WebsiteLeads extends React.Component {
  constructor(props){

    document.title = "Sweep Dealer Dashboard - Website Leads";

    super(props)
  }

  render() {
    return (
      <div>
        <div className="row map-leads">
          <SimpleCard
            className="col-xl-12"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}> 
              <span className="info-icon">
                <InfoIcon />
              </span>
              <span>Website leads are logged when a user clicks the 'Website' button displayed in each of your listings on the Sweep app.</span>
            </div>
          </SimpleCard>
          <Card
            title="Website Leads"
            className="col-xl-12"
          >
              <WebsiteLeadsTable />
          </Card>
        </div>
      </div>
    )
  }
}

WebsiteLeads.defaultProps = {
  dealerMode: true
}
