import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"

import ConversationActionCell from "./cells/ConversationActionCell"

import FinanceApplicationModal from '../modal/FinanceApplication'

import ChatPortal from "../chat/ChatPortal"

import { formatTableID } from '../../../utils/Formatter';
import Backend from "../../../utils/Backend"


export default class CallBackRequestsTable extends React.Component {
  state = {
    dealer: null,

    showApplicationPrint: false,
    selectedApplication: null,

    showChat: false,
    selectedConversation: null
  }

  componentDidMount() {
    this.fetchDealer();
  }

  fetchDealer() {
    Backend.fetchDealerInfo().then(info => {
      this.setState( {
        dealer: info.dealer_staff.dealer
      });
    });
  }

  showChat(conversation) {
    this.setState({
      showChat: true,
      selectedConversation: conversation
    })
  }
  hideChat() {
    this.setState({
      showChat: false,
      selectedConversation: null
    });
  }
  openFinanceApp(finance_id) {
    Backend.getFinanceApplication(finance_id)
    .then((data) => {
      this.hideChat();
      
      data.vehicle.dealer = this.state.dealer;
      this.setState({ 
        selectedApplication: data,
        showApplicationPrint: true 
      })
    }).catch(e => alert(e))
  }


  _renderChat() {
    let {
      showChat,
      selectedConversation
    } = this.state;

    if (!showChat) return null;

    const user = selectedConversation.customer.user
    const dealer = selectedConversation.vehicle.dealer;
    const vehicle = selectedConversation.vehicle;

    return (
      <ChatPortal
        openFinanceApp={ this.openFinanceApp.bind(this) }
        conversationId={selectedConversation.conversation_id}
        user={user} dealer={dealer} vehicle={vehicle}
        hideChat={() => this.hideChat()}
      />
    )
  }

  _getColumns() {
    let columns = [
      {
        Header: 'CB ID',
        id: 'id',
        accessor: chat => formatTableID(chat.id * 49)
      },
      {
        Header: 'Date, Time',
        id: 'created_at',
        accessor: request => moment(request.created_at)
          .calendar(null, {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'DD/MM/YYYY, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'DD/MM/YYYY, HH:mm',
            sameElse: 'DD/MM/YYYY, HH:mm'
          })
      },
      {
        Header: 'Customer',
        id: "customer.user.first_name",
        accessor: request => {
          return `${request.customer.user.first_name} ${request.customer.user.last_name}`
        }
      },
      {
        Header: 'Phone',
        id: "customer.user.phone_number",
        accessor: request => {
          return request.customer.user.phone_number
        }
      },
      {
        Header: 'Email',
        id: "customer.user.email",
        accessor: request => {
          return request.customer.user.email
        }
      },

      {
        Header: 'Vehicle',
        id: "vehicle.model.name",
        accessor: request => {
          return `${request.vehicle.model.manufacturer.name} ${request.vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'vehicle.registration_number'
      },
      {
        id: "actions",
        Header: 'Actions',
        sortable: false,
        Cell: rowInfo => {
          return (
            <ConversationActionCell
              conversation={rowInfo.original}
              onChatClick={conversation => this.showChat(rowInfo.original)}
            />
          )
        }
      }
    ]

    return columns
  }

  render() {
    const columns = this._getColumns()

    const { showApplicationPrint, selectedApplication } = this.state;

    return (
      <div>
        <BaseTable
          searchButton={true}
          endpoint={`${window.Api.Submission}?form_type=2`}
          columns={columns}
          placeholder="Search"
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />

        { showApplicationPrint && 
          <FinanceApplicationModal 
            data={selectedApplication}
            show={showApplicationPrint} 
            onHide={() => {
              this.setState({ 
                selectedApplication: null,
                showApplicationPrint: false
              })
            }}
          />
        }

        { this._renderChat() }
      </div>
    )
  }
}

CallBackRequestsTable.defaultProps = {
  dealerMode: true
}
