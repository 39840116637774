
import moment from 'moment'

export const formatTimestamp = (timestamp) => {
  return moment(timestamp).calendar(null, {
    sameDay: '[Today], HH:mm',
    lastDay: '[Yesterday], HH:mm',
    lastWeek: 'DD MMM YYYY, HH:mm',
    sameElse: 'DD MMM YYYY, HH:mm'
  });
}

export const formatCurrency = (price) => {
  return price.toLocaleString('en-EU', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  });
}

export const formatMiles = (car) => {
  let mileage_int = parseInt(car.mileage)
  if (car.mileageunits 
      && car.mileageunits.toLowerCase().charAt(0) === "m"
      && (car.source === "skupenet"
      || car.source === "beep_beep"
      || car.source === "happydealer")
  ) {

    mileage_int = Math.round(mileage_int * 1.60934);
  }
  else if (car.source === "simi" || car.source === "cars_ireland") {
    mileage_int = Math.round(mileage_int * 1.60934);
  }
  return mileage_int;
}

export const formatNumber = (number) => {
  if(number === undefined || number === null || typeof number === 'object') {
    return number;
  }
  let str = number+"";
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatTableID = (id) => {
  id = formatNumber(id)
  return id.replace(",", "");
}