import React from "react"
import StaffDetail from './StaffDetail'
import Backend from '../../../utils/Backend'
import AuthManager from '../../../utils/AuthManager'

export default class DealerDetail extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      dealer: props.dealer,
      isAdmin: AuthManager.currentUser.role === "admin"
    }
  }

  _renderMembers(){
    let {
      dealer,
      isAdmin
    } = this.state
    let showDelete = dealer.staff.length > 1;

    let staff = [
      ...dealer.staff
    ]

    if(isAdmin){
      staff.push({
        user: {}
      })
    }

    return staff.map((member, index) => {
      return (
        <StaffDetail
          dealer={dealer}
          staff={member}
          showDelete={showDelete && isAdmin}
          canEdit={isAdmin}
          onDeleteClicked={() => this._handleDeleteMember(index)}
          onAdded={member => {
            dealer.staff.push(member)
            this.setState({ dealer })
          }}
        />
      )
    })
  }

  _addMember(){
    let {
      dealer
    } = this.state

    this.setState({
      staff: [
        ...dealer.staff,
        {
          user:{}
        }
      ]
    })
  }

  _handleDeleteMember(index){
    let {
      dealer
    } = this.state

    if(dealer.staff.length == 1){
      return
    }

    dealer.staff.splice(index, 1)

    this.setState({ dealer })
  }

  render() {

    return (
      <div className="row">
				<div className="col-md-6">
					{ this._renderMembers() }
				</div>
			</div>
    )
  }
}
