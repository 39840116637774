import React from "react"

import Card from './components/cards/Card'
import SimpleCard from './components/cards/SimpleCard'

import FinanceApplicationsTable from './components/tables/FinanceApplicationsTable'
import {ReactComponent as InfoIcon} from '../assets/icons/info.svg';

import Backend from '../utils/Backend'
import './FinanceApplications.css';

export default class FinanceApplications extends React.Component {
  constructor(props){
    super(props)

    if(props.location.query){
      this.initial_conversation_id = props.location.query.conversation_id
    }

    document.title = "Sweep Dealer Dashboard - Finance Applications";

    this.state = {
      loading: false,
      searchTerm: '',
      statusFilters: [],
      dealer: null,
      loanPartner: ""
    }
    this.financeApplicationsTable = React.createRef();
  }

  componentDidMount(){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let initialConversationId = params.get('conversation_id');

    if(initialConversationId){
      this._loadConversation(initialConversationId)
    }
    this.fetchDealer()
  }

  fetchDealer() {
    Backend.fetchDealerInfo().then(info => {
      this.setState( {
        dealer: info.dealer_staff.dealer
      });

      const intermediary_source = info.dealer_staff.dealer.credit_intermediary_source;

      let loanPartner = "";
      if (intermediary_source && !info.dealer_staff.dealer.credit_intermediary_active) {
        if (intermediary_source.toLowerCase().includes("bluestone")) {
          loanPartner = "Bluestone Finance"
        }
        else if (intermediary_source.toLowerCase().includes("loanitt")) {
          loanPartner = "LoanITT"
        }
        else if (intermediary_source.toLowerCase().includes('motor')) {
          loanPartner = intermediary_source;
        }
      }
      this.setState({
        loanPartner: loanPartner
      })
    });
  }

  _loadConversation(conversationId){
    Backend.getConversation(conversationId)
      .then(conversation => {
        this.financeApplicationsTable.current.showChat(conversation)
      })
      .catch(() => {

      })
  }

  _getPartnerLink() {
    let { loanPartner } = this.state;

    if (loanPartner) {
      loanPartner = loanPartner.toLowerCase();

      if (loanPartner.includes('loanitt')) return "https://www.loanitt.ie/contact-us-car-finance/"
      if (loanPartner.includes('bluestone') || loanPartner.includes('motor'))
        return "https://www.bluestoneam.ie"
    }

    return "";
  }

  render() {
    let { loading, dealer, loanPartner } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row finance-application">
          {(loanPartner && loanPartner !== "") && (
            <SimpleCard
              className="col-xl-12"
            >
              <div>
                <span className="info-icon">
                  <InfoIcon />
                </span>
                <span>Your finance applications are currently handled by our partners at { loanPartner }. Please contact { loanPartner } with any queries you may have.</span>
                <button className="contactbtn btn btn-primary btn-small" onClick={() => window.open(this._getPartnerLink(),'_blank')}>Contact { loanPartner.split(" ")[0] }</button>
              </div>
            </SimpleCard>
          )}
          <Card
            title="Finance Applications"
            className="col-xl-12"
          >
            <FinanceApplicationsTable
              showApplication={loanPartner === ""}
              dealer={dealer}
              ref={this.financeApplicationsTable}
            />
          </Card>
        </div>
      </div>
    )
  }
}

FinanceApplications.defaultProps = {
  dealerMode: true
}
