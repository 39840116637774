import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"
window.Api.User.WebPush               = window.Api.User.Base + "/device/wp"


window.Api.Dealers             = window.Api.Base + "/dealers"
window.Api.Vehicles            = window.Api.Base + "/vehicles"
window.Api.Stats               = window.Api.Base + "/stats"
window.Api.Staff               = window.Api.Base + "/staff"
window.Api.Images              = window.Api.Base + "/images"
window.Api.Submission          = window.Api.Base + "/submission"
window.Api.Conversations       = window.Api.Base + "/conversations"
window.Api.Messages            = window.Api.Base + "/messages"
window.Api.Calls               = window.Api.Base + "/calls"
window.Api.Track               = window.Api.Base + "/track"
window.Api.FinanceApplications = window.Api.Base + "/finance-applications"
window.Api.WarmLeads           = window.Api.Base + "/impressions"
window.Api.MessageToCustomer   = window.Api.Base + "/lead_message"




window.Api.WEB_PUSH_APP_SERVER_KEY = env.REACT_APP_WEB_PUSH_APP_SERVER_KEY
