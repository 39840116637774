export const ROUTES = {
  Dashboard: '/',
  Chats: '/messages',
  PhoneCalls: '/phone-calls',
  CallBackRequests: '/call-back-requests',
  FinanceApplications: '/finance-applications',
  TestDriveRequests: '/test-drive-requests',
  MapLeads: '/map-leads',
  WebsiteLeads: '/website-leads',
  Listings: '/listings',
  Dealership: '/settings',
  Staff: '/staff'
}