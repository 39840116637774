import React from 'react'

import {
  ThemeProvider,
  MessageList,
  TextComposer,
  TextInput,
  SendButton,
  MessageGroup
} from '@livechat/ui-kit'

import Message from './Message'

import Backend from '../../../utils/Backend'

import moment from 'moment'

const SOUND_MESSAGE_SENT = require('../../../assets/sounds/message_sent.wav')

export default class Chat extends React.Component {
  constructor(props){
    super(props)

    this.audioMessageSent = new Audio(SOUND_MESSAGE_SENT)
    this.chatRef = React.createRef();

    this.state = {
      conversation: props.conversation,
      messages: [],
      impressions: undefined,
      lastLoadId: null,
      nextPageUrl: null,
      dealer: props.dealer
    }
  }

  componentDidMount(){
    this._getChatImpressions().then((impressions) => {
      this.setState({ impressions })
    })
    .catch(() => console.log("Cannot fetch impressions"))
    .finally(() => {
      this._loadMessages()
    });
  }

  componentDidUpdate() {
    setTimeout(() => {
      // scroll to bottom of chat on update
      if (this.chatRef && this.chatRef.current && this.chatRef.current.children[0]) {
        this.chatRef.current.children[0].scrollTo(0, 9999999);
      }
    }, 100)
  }

  async _getChatImpressions() {
    return Backend.getChatImpressions(this.props.vehicle_id, this.props.user.id);
  }

  substractMinute(timestamp) {
    const timeArray = timestamp.split(":");
    
    let minute = parseInt(timeArray[1]) - 1;

    if (minute < 0) minute = "00";
    else if (minute < 10) minute = `0${minute}` 

    let timeString = timeArray[0] + `:${minute}:${timeArray[2]}`;
    
    return timeString;
  }

  _addImpressionsToMessages(messages) {
    let { impressions } = this.state

    if (impressions && impressions.length > 0) {
      // find message to base lead impressions messages on it
      let message;
      for (let m of messages) {
        if (m.sender.role === "customer") {
          message = Object.assign({}, m);
        }
      }

      if (!message) {
        message = {
          sender: this.props.user
        }
      }

      message.read = true;
      message.message_type = "impression_type";

      let shortlisted, finance_application, firstImpression = false;

      for (let impression of impressions) {
        const lead_message = Object.assign({}, message);

        lead_message.created_at = impression.created_at;

        if (impression.website_lead) {
          lead_message.text = "WEBSITE_LEAD $";
        }
        
        else if (impression.shortlisted) {
          lead_message.text = "SHORTLISTED $";
          firstImpression = true;
          shortlisted = impression;
        }

        else if (impression.finance_application) {
          lead_message.text = impression.finance_application.id + " FINANCE_APPLICATION $ ";

          if (impression.loan_processor && impression.loan_processor.length > 0) {
            lead_message.text += impression.loan_processor.toLowerCase();
          }
          
          firstImpression = true;
          finance_application = impression;
        }

        messages.push(lead_message);
      }

      if (firstImpression) {
        const first_impression_message = Object.assign({}, message);
        first_impression_message.text = "FIRST_IMPRESSION $";

        let created_at;

        if (shortlisted && finance_application) {
          if (moment.utc(shortlisted.created_at).diff(moment.utc(finance_application.created_at))) {
            created_at = finance_application.created_at;
          }
          else {
            created_at = shortlisted.created_at
          }
        }
        else if (shortlisted) created_at = shortlisted.created_at
        else created_at = finance_application.created_at;

        first_impression_message.created_at = this.substractMinute(created_at);

        messages.push(first_impression_message);
      }

      messages.sort((left, right) => {
        return moment.utc(right.created_at).diff(moment.utc(left.created_at))
      });
    }

    return messages
  }

  _loadMessages(){
    let {
      nextPageUrl,
      conversation
    } = this.state

    Backend.getMessages(conversation, nextPageUrl)
    .then(({ messages, nextPageUrl }) => {
      messages = this._addImpressionsToMessages(messages);

      this.setState({ messages, nextPageUrl })
    })
    .catch(error => {
      console.log(error);
    })
  }

  _handleSendMessage(text){
    let {
      messages,
      conversation,
    } = this.state
    Backend.sendMessage(conversation, text)
    .then(message => {
      messages.unshift(message)
      this.audioMessageSent.play()
      this.setState({ messages })
    })
  }

  _renderMessages(messages){
    return messages.reverse().map((message, index) => {
      return (
        <MessageGroup key={index} onlyFirstWithMeta>
       
          <Message
            openFinanceApp={this.props.openFinanceApp}
            dealer={this.state.dealer}
            message={message}
            onSeen={() => {
              if(index === messages.length - 1){

                //this._loadMessages()
              }
            }}/>
        </MessageGroup>
      )
    })
  }

  render(){
    let {
      messages
    } = this.state
    return (
      
      <ThemeProvider>
    
        <div className="row chat-conversation" ref={this.chatRef}>

          <MessageList
            active
            containScrollInSubtree
            className="chat-list"
          >
            { this._renderMessages(messages) }
          </MessageList>
        </div>

        <div className="row" style={{width: "100%"}}>
          <TextComposer
            style={{width: "100%"}}
            placeholder="Type here..."
            onSend={text => {
              this._handleSendMessage(text)
            }}
          >
            
                <div className="row chat-input-container" style={{width: "100%"}}>
                    <TextInput
                      maxRows={10}
                      className="chat-input col-9 col-sm-10"
                    />
                    <div className="col-3 col-sm-2">
                      <SendButton className="chat-send-button pull-right" />
                    </div>
                </div>
                
          </TextComposer>
        </div>
      </ThemeProvider>
    )
  }
}

Chat.defaultProps = {
  // height: 638
}
