import React from "react"

import Card from './components/cards/Card'
import PhoneCallsTable from './components/tables/PhoneCallsTable'
import Backend from '../utils/Backend'

export default class PhoneCalls extends React.Component {
  constructor(props){
    super(props)

    document.title = "Sweep Dealer Dashboard - Phone Calls";

    this.state = {
      loading: false
    }
  }

  render() {
    let {
      loading,
    } = this.state

    if(loading){
      return null
    }

    return (
      <div className="row">
        <Card
          title="Phone Calls"
          className="col-xl-12">
          <PhoneCallsTable />
        </Card>
      </div>
    )
  }
}

PhoneCalls.defaultProps = {
  dealerMode: true
}
