import AuthManager from './AuthManager'

// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests
export default class FetchHelper {
  static get(endpoint, validateTokens = true) {
    return FetchHelper.request('GET', endpoint, null, false, validateTokens)
  }

  static post(endpoint, data, isMultipart = false, validateTokens = true) {
    return FetchHelper.request('POST', endpoint, data, isMultipart, validateTokens)
  }

  static patch(endpoint, data, isMultipart = false, validateTokens = true) {
    return FetchHelper.request('PATCH', endpoint, data, isMultipart, validateTokens)
  }

  static put(endpoint, data, isMultipart = false, validateTokens = true) {
    return FetchHelper.request('PUT', endpoint, data, isMultipart, validateTokens)
  }

  static delete(endpoint, data, validateTokens = true) {
    return FetchHelper.request('DELETE', endpoint, data, false, validateTokens)
  }

  static async request(method, endpoint, data, isMultipart = false, validateTokens = true) {
    let body = null
    if(data){
      body = isMultipart ? data : JSON.stringify(data)
    }

    let headers = isMultipart ? await AuthManager.getHeaders("multipart/form-data") : await AuthManager.getHeaders()

    let statusCode = null

    return FetchHelper._handleValidateTokens(validateTokens)
    .then(() => fetch(endpoint, { method, body, headers }))
    .then(response => {
      statusCode = response.status
      if(response.status == 204){
        return response
      }
      return response.json();
    })
    .then(responseJson => {
      let status = { code: statusCode, success: responseJson.status }

      if (this._hasError(status)) {
        throw FetchHelper._getError(responseJson)
      }

      return responseJson
    })
  }

  static _handleValidateTokens(validateTokens) {
    return new Promise((resolve, reject) => {
      if (validateTokens) {
        return AuthManager.validateTokens(resolve, reject)
      }
      resolve()
    })
  }

  static _hasError({ code, success }) {
    return code < 200 || code > 299 || success == false
  }

  static _getError(responseJson) {
    let error = null

    if (responseJson.message) {
      error = responseJson.message
    } else if (responseJson.non_field_errors) {
      error = responseJson.non_field_errors
    } else {
      error = responseJson
    }

    if (error.constructor == Object) {
      for (var key in error) {
        error = key + ': ' + FetchHelper._getError(error[Object.keys(error)[0]]).message
        break
      }
    }

    let message = 'An unexpected error occured'

    if (error instanceof Array) {
      message = error[0]
    } else if (typeof error === 'string') {
      message = error
    }
    if (error.constructor == Object) {
      for (key in error) {
        message = error[key]
      }
    }

    return { error: error, message: message }
  }

  static _hasMore(results, pageLimit) {
    if (results.current_page) {
      return results.current_page < results.last_page
    }
    return results.data.length >= pageLimit
  }
}
