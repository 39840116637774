import React from 'react';

import './Spinner.css';

export const SpinnerContext = React.createContext(false)

const Spinner = ({children}) => {
  const [spinner, setSpinner] = React.useState(false)
  return (
    <SpinnerContext.Provider
      value={{
          spinner,
          toggleSpinner: (flag) => setSpinner(flag)
      }}>
      { spinner && (
        <div className="modal-backdrop show">
          <div className="spinner spinner-grow text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="visually-hidden"></span>
          </div>
          {/* <div className="spinner spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div> */}
        </div>
      )}
      {children}
    </SpinnerContext.Provider>    
  )
}

export default Spinner;