import React from 'react'

import {
  Avatar,
  MessageGroup,
  Message,
  MessageMedia,
  MessageText,
  MessageTitle,
} from '@livechat/ui-kit'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'

import { formatTimestamp } from '../../../utils/Formatter'

export default class CustomMessage extends React.Component {
  state = {
    finance: false,
    message: this.props.message,
    dealer: this.props.dealer
  }

  componentDidMount() {
    this.processMessage()
  }

  processMessage() {
    let {
      message,
      dealer
    } = this.state;

    let sender = message.sender;
    const phone_number = (sender.phone_country_code ? sender.phone_country_code : "") + (sender.phone_number ? sender.phone_number : "");

    if (message.text.indexOf("SHORTLISTED $") > -1) { 
      message.text = sender.first_name + ' shortlisted your vehicle'
    } 

    else if (message.text.indexOf("You requested a callback from") > -1) {
      
      message.text = sender.first_name + " requested a callback: " + message.text.replace("You requested a callback from " + dealer.name, "").replace(".", "") + (phone_number.length > 0 ? ' on <a class="message-link" href="tel:' + phone_number + '">' + phone_number + '</a>' : "");
    } 
    else if (message.text.indexOf("You requested a call back from") > -1) {
      
      message.text = sender.first_name + " requested a callback: " + message.text.replace("You requested a callback from " + dealer.name, "").replace(".", "") + (phone_number.length > 0 ? ' on <a class="message-link" href="tel:' + phone_number + '">' + phone_number + '</a>' : "");
    } 
    
    else if (message.text.indexOf("You requested a test drive at") > -1) { 
      /* message.text = sender.first_name + " requested a test drive: " + message.text.replace("You requested a test drive at " + dealer.name, "") + (phone_number.length > 0 ? ' on <a class="message-link" href="tel:' + phone_number + '">' + phone_number + '</a>' : ""); */
      message.text = sender.first_name + " requested a test drive: " + message.text.replace("You requested a test drive at " + dealer.name, "")
    } 
    
    else if (message.text.indexOf("You Called") > -1) { 
      message.text = sender.first_name + ' called your dealership on <a class="message-link" href="tel:' + dealer.phone + '">' + dealer.phone + '</a>' 
    } 

    else if (message.text.indexOf("You Got Directions") > -1) { 
      message.text = sender.first_name + ' got map directions to your dealership.'
    } 

    else if (message.text.indexOf("FIRST_IMPRESSION $") > -1) { 
      message.text = sender.first_name + ' saw your vehicle in the Sweep app';
    } 

    else if (message.text.indexOf("FINANCE_APPLICATION $") > -1) { 
      let text = sender.first_name + ' applied for finance';

      if (message.text.indexOf("bluestone") > -1) {
        text += ':\n\n Your Finance Applications are processed by <a class="message-link" href="https://www.bluestoneam.ie/IE/Asset-Finance-Overview" target="_blank">Bluestone</a>.'
      }
      else if (message.text.indexOf("loanitt") > -1) {
        text += ':\n\n Your Finance Applications are processed by <a class="message-link" href="https://www.loanitt.ie" target="_blank">LoanITT</a>.'
      }
      else {
        this.setState({ finance: true });
        text = message.text.split(" ")[0]; // gets id of finance app
      }

      message.text = text;
    }

    else if (message.text.indexOf("WEBSITE_LEAD $") > -1) { 
      message.text = sender.first_name + ' clicked through to your website'
    } 

    else if (message.message_type === 'whatsappChat') { 
      message.text = sender.first_name + ' sent a WhatsApp message to <a class="message-link" href="tel:' + dealer.whatsapp_number + '">' + dealer.whatsapp_number + '</a>:\n\n' + message.text
    }

    this.setState({ message })
  }

  renderFinanceMessage() {
    let {
      message
    } = this.state;

    const finance_id = message.text;

    return (
      <>
        { `${message.sender.first_name} applied for ` } 
        <span className="message-link" onClick={() => this.props.openFinanceApp(finance_id)}>finance</span>.
      </>
    )
  }

  render() {
    let {
      message,
      dealer
    } = this.state

    let sender = message.sender
    // let isOwn = sender.id === AuthManager.currentUser.user.id
    let isCustomer = message.sender.role === "customer";
    let className = !isCustomer ? "is-sender" : "is-recipient";

    let avatar = null
    if(!!isCustomer){
      avatar = message.sender.avatar ? message.sender.avatar.thumbnail : null
    }

    let isAuthor = sender.id === AuthManager.currentUser.user.id || !isNaN(sender)

    let authorName = isAuthor ? "" : `${sender.first_name} ${sender.last_name}`;
    let dealerName = !isCustomer ? dealer.name ? dealer.name : 'Me' : '';
    let is_automated = ""

    if (message.text && message.text.indexOf("Please let us know if you have any questions regarding this vehicle") > -1) {
      is_automated = " (automated message)"
    }
    
    let date = formatTimestamp(message.created_at);

    return (
      <MessageGroup
        onlyFirstWithMeta
        isOwn={!isCustomer}
        avatar={avatar}
      >

        <Message
          authorName={(isCustomer ? authorName : dealerName + is_automated) + " - "}
          date={date}
          className={"chat-message " + className}
          isOwn={!isCustomer}
          onSeen={() => {
            if(!!isCustomer && !message.read){
              Backend.markRead(message)
            }
            this.props.onSeen()
          }}
        >
          <MessageText className="chat-message-body">
            
            { 
              this.state.finance 
              ? this.renderFinanceMessage() 
              : <span dangerouslySetInnerHTML={{__html: message.text }} />
            } 

          </MessageText>
        </Message>
      </MessageGroup>
    )
  }
}
