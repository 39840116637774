import React from "react"

import DealerDetail from './components/dealer/DealerDetail'
import StatCard from './components/cards/StatCard'
import AuthManager from '../utils/AuthManager'
import Backend from '../utils/Backend'
import { SpinnerContext } from '../components/Spinner';

export default class Settings extends React.Component {
  static contextType = SpinnerContext;
  constructor(props){
    super(props)

    document.title = "Sweep Dealer Dashboard - Staff";

    this.state = {
      dealer: null,
      loading: true,
    }
  }

  componentDidMount(){
    this._loadDealer()
  }

  _loadDealer(){
    this.context.toggleSpinner(true)
    Backend.getDealer(AuthManager.currentDealer.id)
      .then(dealer => {
        this.setState({
          dealer,
          loading: false
        });
        this.context.toggleSpinner(false)
      })
      .catch(e => {
        this.context.toggleSpinner(true)
      }) 
  }

  _goToDealers(){
    this.props.history.push(`/dealers`)
  }

  render() {
    let {
      dealer,
      loading
    } = this.state

    if(loading){
      return null
    }

    return [
      <StatCard
        title="Active Staff Members"
        value={ dealer.staff.length }
        size="col-md-3 col-lg-3 col-xl-3 p-0"
      />,
      <DealerDetail
        dealer={dealer}
        goToDealers={() => this._goToDealers()}
      />
    ]
  }
}
