import React from "react"
import { withRouter } from "react-router-dom";
import {ReactComponent as DashboardImage} from '../../assets/icons/dashboard.svg';
import {ReactComponent as ChatImage} from '../../assets/icons/chat.svg';
import {ReactComponent as PhoneImage} from '../../assets/icons/phone.svg';
import {ReactComponent as FinanceImage} from '../../assets/icons/finance.svg';
import {ReactComponent as MapImage} from '../../assets/icons/map.svg';
import {ReactComponent as ListingImage} from '../../assets/icons/listing.svg';
import {ReactComponent as DealershipImage} from '../../assets/icons/dealership.svg';
import {ReactComponent as StaffImage} from '../../assets/icons/staff.svg';
import {ReactComponent as CallBackImage} from '../../assets/icons/callback.svg';
import {ReactComponent as TestDriveImage} from '../../assets/icons/drive.svg';
import {ReactComponent as WebsiteImage} from '../../assets/icons/website.svg';
import {ROUTES} from '../../constants/Routes';

import "./LeftMenu.css";
class LeftMenu extends React.Component {
  state = {

  }

  componentDidMount(){
    this._updateActive()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this._updateActive();
    }
  }

  _goTo(url){
    this.props.history.push(url)

    if (window && window.ktlayout) {
      window.ktlayout.closeMobileAsideMenuOffcanvas();
    }
  }

  _updateActive() {
    this.setState({ activeRoute: this.props.location.pathname })
  }


  render() {

    const { dealer } = this.props;

    let {
      activeRoute,
    } = this.state

    return (
      <div>
        <button className="kt-aside-close " id="kt_aside_close_btn">
          <i className="la la-close">
          </i>
        </button>
        <div className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
          <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
            <div className="kt-aside__brand-logo">
              <a onClick={() => this._goTo(ROUTES.Dashboard)}>
      				  <img alt="Logo" src={require('../../assets/img/logo.svg')} height="30px"/>
      		    </a>
            </div>
            <div className="kt-aside__brand-tools">
              <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
        				<span>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
          					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          						<polygon id="Shape" points="0 0 24 0 24 24 0 24" />
          						<path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" id="Path-94" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) " />
          						<path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" id="Path-94" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) " />
          					</g>
          				</svg>
                </span>
        				<span>
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon">
          					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          						<polygon id="Shape" points="0 0 24 0 24 24 0 24" />
          						<path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" id="Path-94" fill="#000000" fillRule="nonzero" />
          						<path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" id="Path-94" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) " />
          					</g>
          				</svg>
                </span>
        			</button>
              {/*<button className="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left" id="kt_aside_toggler"><span></span></button> */}
            </div>
          </div>

          <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
            <div id="kt_aside_menu" className="kt-aside-menu " data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500">
              <ul className="kt-menu__nav ">
                <li className="kt-menu__item" className={activeRoute === ROUTES.Dashboard ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.Dashboard)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <DashboardImage />
                    </span>
                    <span className="kt-menu__link-text">Dashboard</span>
                  </a>
                </li>
                <li className="kt-menu__section ">
                  <h4 className="kt-menu__section-text">Leads</h4>
                  <i className="kt-menu__section-icon flaticon-more-v2"></i>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.Chats ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.Chats)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <ChatImage />
                    </span>
                    <span className="kt-menu__link-text">Chats & WhatsApps</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.PhoneCalls ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.PhoneCalls)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <PhoneImage />
                    </span>
                    <span className="kt-menu__link-text">Phone Calls</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.CallBackRequests ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.CallBackRequests)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <CallBackImage />
                    </span>
                    <span className="kt-menu__link-text">Callback Requests</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.FinanceApplications ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.FinanceApplications)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <FinanceImage />
                    </span>
                    <span className="kt-menu__link-text">Finance Applications</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.TestDriveRequests ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.TestDriveRequests)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <TestDriveImage />
                    </span>
                    <span className="kt-menu__link-text">Test Drive Requests</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.MapLeads ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.MapLeads)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <MapImage />
                    </span>
                    <span className="kt-menu__link-text">Map Leads</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.WebsiteLeads ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.WebsiteLeads)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <WebsiteImage />
                    </span>
                    <span className="kt-menu__link-text">Website Leads</span>
                  </a>
                </li>

                <li className="kt-menu__section ">
                  <h4 className="kt-menu__section-text">Vehicles</h4>
                  <i className="kt-menu__section-icon flaticon-more-v2"></i>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.Listings ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.Listings)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <ListingImage />
                    </span>
                    <span className="kt-menu__link-text">Listings</span>
                  </a>
                </li>
                <li className="kt-menu__section ">
                  <h4 className="kt-menu__section-text">Settings</h4>
                  <i className="kt-menu__section-icon flaticon-more-v2"></i>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.Dealership ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.Dealership)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <DealershipImage />
                    </span>
                    <span className="kt-menu__link-text">Dealership</span>
                  </a>
                </li>
                <li className="kt-menu__item" className={activeRoute === ROUTES.Staff ? "kt-menu__item kt-menu__item--active" : "kt-menu__item"} aria-haspopup="true">
                  <a onClick={() => this._goTo(ROUTES.Staff)} className="kt-menu__link ">
                    <span className="kt-menu__link-icon">
                      <StaffImage />
                    </span>
                    <span className="kt-menu__link-text">Staff</span>
                  </a>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default withRouter(LeftMenu);
