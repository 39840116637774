import React from "react"

import Div from './components/layouts/Div'

import moment from 'moment'

import StatCard from './components/cards/StatCard'

import Backend from '../utils/Backend'
import './Overview.css'
import { ROUTES } from '../constants/Routes';

import { SpinnerContext } from '../components/Spinner';
import { formatNumber } from '../utils/Formatter';

const monthNameShort=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
export default class Overview extends React.Component {
  static contextType = SpinnerContext

  constructor(props){
    super(props);

    document.title = "Sweep Dealer Dashboard";

    const currentYear = moment().year();
    const years = [];

    for(let i = 2019; i <= currentYear; i++) {
      years.push(i);
    }

    console.log([...Array(12).keys()])

    this.state = {
      searchTerm: '',
      loading: true,
      loadingLatestDealers: true,
      loadingLatestVehicles: true,
      stats: {},
      latestDealers: [],
      latestVehicles: [],
      selectedMonth: null,
      selectedYear: null,
      yearOptions: years,
      monthOptions: [...Array(12).keys()]
    }
  }

  async componentDidMount() {
    const { toggleSpinner } = this.context;
    try {
      toggleSpinner(true);
      await this._loadStats();
      toggleSpinner(false);
    } catch (e) {
      toggleSpinner(false);
    }
  }

  _loadStats() {
    const  { selectedMonth, selectedYear } = this.state;
    let params = {}
    if(!selectedYear && selectedMonth) {
      alert("Please select a Year");
      return;
    }
    if(selectedYear) {
      params.year = selectedYear;
    }
    if(selectedMonth) {
      params.month = +selectedMonth + 1;
    }
    params = new URLSearchParams(params).toString();
    
    return Backend.getStats(params,false)
      .then(stats => {
        this.setState({
          stats,
          loading: false
        })
      })
      .catch(error => {
        alert(error.message)
      })
  }

  _goTo = (url) => {
    this.props.history.push(url)
  }

  yearChangeHandler({target: { value }}) {
    const { toggleSpinner } = this.context;

    const array = value.split("-"); 
    this.setState(() => {
      return {
        selectedYear: array[0],
        selectedMonth: array[1]
      }
    }, async () => {
      try {
        toggleSpinner(true)
        await this._loadStats()
        toggleSpinner(false)
      } catch(e) {
        toggleSpinner(false)
      }
    })
  }

  render() {
    let {
      stats,
      loading, 
      yearOptions,
      monthOptions
    } = this.state;

    const currentYear = moment().year();
    const currentMonth = moment().month();

    if(loading){
      return null
    }

    return (
      <Div
        className="overview-cards"
      >
        <div className="row">
  				<StatCard
            title="Choose Month"
            size="col-md-3 col-lg-3 col-xl-3"
          >
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-6 mt-3">
                <select className="month-select" onChange={this.yearChangeHandler.bind(this)}>
                  <option value="">Month</option>
                  { 
                    yearOptions.map((yearValue) => {
                      return monthOptions.map((monthValue, monthIndex) => {
                        if (yearValue === currentYear && monthValue > currentMonth) {
                          return null 
                        }
                        return (
                          <option value={`${yearValue}-${monthValue}`} key={monthIndex}>
                            {`${monthNameShort[monthValue]} ${yearValue}`}
                          </option>
                        )
                      }).reverse()
                    }).reverse()
                  }
                </select>
              </div>
            </div>
            
          </StatCard>
        </div>
        <div className="row">
  				<StatCard
            title="Vehicles Viewed"
            value={ formatNumber(stats.vehicles_viewed) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The amount of times your vehicles have been viewed and swiped"
          />
          <StatCard
            title="Vehicles Saved"
            value={ formatNumber(stats.vehicles_saved) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The amount of times your vehicles have been saved (a.k.a shortlisted, or swiped right)"
            onClick={() => this._goTo(ROUTES.Shortlists)}
          />
          <StatCard
            title="Total Leads"
            value={ formatNumber(stats.total_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The sum of Chats + Phone Calls + Finance Applications + Map Leads"
          />
          <StatCard
            title="Chat Leads"
            value={ formatNumber(stats.chat_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer has wrote to you through Sweep's in-app chat"
            onClick={() => this._goTo(ROUTES.Chats)}
          />
           
  			</div>
        <div className="row">
        <StatCard
            title="WhatsApp Leads"
            value={ formatNumber(stats.whatsapp_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer sent a WhatsApp message to you via the Sweep app."
            onClick={() => window.location.href="https://web.whatsapp.com/"}
          />
  				<StatCard
            title="Phone Call Leads"
            value={ formatNumber(stats.phone_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer has called you via the Sweep app"
            onClick={() => this._goTo(ROUTES.PhoneCalls)}
          />
          <StatCard
            title="Finance Applications"
            value={ formatNumber(stats.finance_applications) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer has submitted a finance application for one of your vehicles through Sweep's in-app finance application form"
            onClick={() => this._goTo(ROUTES.FinanceApplications) }
          />
          <StatCard
            title="Map Leads"
            value={ formatNumber(stats.map_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer has tapped the 'Get Directions' button displayed on each of your listings in the Sweep app, which opens a Google Maps or Apple Maps link to your dealership address"
            onClick={() => this._goTo(ROUTES.MapLeads) }
          />
          
        
  			</div>        
        <div className="row">
        <StatCard
            title="Call Back Leads"
            value={ formatNumber(stats.callback_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer requested a call back via the Sweep app."
            onClick={() => this._goTo(ROUTES.CallBackRequests) }
          />
        <StatCard
            title="Test Drive Leads"
            value={ formatNumber(stats.testdrive_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer requested a test drive via the Sweep app."
            onClick={() => this._goTo(ROUTES.TestDriveRequests) }
          />
           <StatCard
            title="Website Leads"
            value={ formatNumber(stats.website_leads) }
            size="col-md-3 col-lg-3 col-xl-3"
            info="The number of times a customer clicked through to your website via the Sweep app."
            onClick={() => this._goTo(ROUTES.WebsiteLeads) }
          />
          <StatCard
              title="Vehicles Shared"
              value={ formatNumber(stats.vehicles_shared) }
              size="col-md-3 col-lg-3 col-xl-3"
              info="The number of times a customer shared one of your vehicles from the Sweep app"
            />
        </div>
      </Div>
    )
  }
}
