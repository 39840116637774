import React from "react"

import Card from './components/cards/Card'
import MapLeadsTable from './components/tables/MapLeadsTable'
import SimpleCard from './components/cards/SimpleCard'
import {ReactComponent as InfoIcon} from '../assets/icons/info.svg';
import './MapLeads.css'
export default class MapLeads extends React.Component {
  constructor(props){

    document.title = "Sweep Dealer Dashboard - Map Leads";

    super(props)
  }

  render() {
    return (
      <div>
        <div className="row map-leads">
          <SimpleCard
            className="col-xl-12"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}> 
              <span className="info-icon">
                <InfoIcon />
              </span>
              <span>Map leads are logged when a user clicks the 'Get Directions' button displayed in each of your listings on the Sweep app. Tapping this button opens Google Maps or Apple Maps, allowing the user to begin their journey to your dealership.</span>
            </div>
          </SimpleCard>
          <Card
            title="Map Leads"
            className="col-xl-12"
          >
              <MapLeadsTable />
          </Card>
        </div>
      </div>
    )
  }
}

MapLeads.defaultProps = {
  dealerMode: true
}
