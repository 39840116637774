import React from 'react'
import Modal from 'react-bootstrap/Modal'
import './ListingDetails.css'
import { formatCurrency } from '../../../utils/Formatter'

export default class ListingDetailsModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      show: props.show,
      agent: props.agent,
      laoding: true,
      info: props.info,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let { show, agent, info } = this.state

    return (
      <Modal
        className="vehicle-model"
        centered
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName="modal-lg">
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="header">Vehicle Stats</div>
            </div>
          </div>
          <div className="row info-wrapper">
            <div className="col-lg-12">
              <div className="info">
                <span className="title">Vehicle: </span>
                <span className="value">{info.year || ''} { info.model.manufacturer.name } { info.model.name } • {formatCurrency(info.price / 100)}</span>
              </div>
              <div className="info">
                <span className="title">Reg: </span>
                <span className="value">{ info.registration_number}</span>
              </div>
            </div>
          </div>
          <div className="row stats">
            <div className="col-6">
              <span>Viewed</span>
            </div>
            <div className="col-6 value">
              <span>{info.views_no || 0}</span>
            </div>
          </div>
          <div className="row stats">
            <div className="col-6">
              <span>Saved</span>
            </div>
            <div className="col-6 value">
              <span>{info.liked_impressions_no || 0}</span>
            </div>
          </div>
          <div className="row stats">
            <div className="col-6">
              <span>Shared</span>
            </div>
            <div className="col-6 value">
            <span>{info.vehicles_shared || 0}</span>
            </div>
          </div>
          <div className="row stats">
            <div className="col-6">
              <span>Leads</span>
            </div>
            <div className="col-6 value">
              <span>{info.total_leads || 0}</span>
            </div>
          </div>
          <section className="row small-stats">
            <div className="col-6">
              <span>Chat Leads</span>
            </div>
            <div className="col-6 value">
            <span>{info.chat_leads || 0}</span>
            </div>
          </section>
          <section className="row small-stats">
            <div className="col-6">
              <span>WhatsApp Leads</span>
            </div>
            <div className="col-6 value">
            <span>{info.whatsapp_leads || 0}</span>
            </div>
          </section>
          <div className="row small-stats">
            <div className="col-6">
              <span>Phone Call Leads</span>
            </div>
            <div className="col-6 value">
            <span>{info.phone_leads || 0}</span>
            </div>
          </div>
          <div className="row small-stats">
            <div className="col-6">
              <span>Callback Requests</span>
            </div>
            <div className="col-6 value">
            <span>{info.callback_leads || 0}</span>
            </div>
          </div>
          <div className="row small-stats">
            <div className="col-6">
              <span>Finance Applications</span>
            </div>
            <div className="col-6 value">
            <span>{info.finance_applications || 0}</span>
            </div>
          </div>
          <div className="row small-stats">
            <div className="col-6">
              <span>Test Drive Requests</span>
            </div>
            <div className="col-6 value">
            <span>{info.testdrive_leads || 0}</span>
            </div>
          </div>
          <div className="row small-stats">
            <div className="col-6">
              <span>Map Leads</span>
            </div>
            <div className="col-6 value">
            <span>{info.map_leads || 0}</span>
            </div>
          </div>
          <div className="row small-stats">
            <div className="col-6">
              <span>Website Leads</span>
            </div>
            <div className="col-6 value">
            <span>{info.website_leads || 0}</span>
            </div>
          </div>
          <div className="row action">
            <button
              type="reset"
              onClick={() => this.props.onHide()}
              className="btn btn-primary">
                Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
