import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"

import VehicleStatusCell from "./cells/VehicleStatusCell"

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import { formatCurrency, formatMiles, formatNumber, formatTableID } from '../../../utils/Formatter';

export default class VehiclesTable extends React.Component {

  constructor(props){
    super(props)

    let endpoint = `${window.Api.Vehicles}?dealer_id=${AuthManager.currentDealer.id}`
    this.state = {
      endpoint,
      dealerMode: props.dealerMode,
    }
  }

  _deactivate(vehicle){
    Backend.deactivateVehicle(vehicle)
    this.props.onDeactivatedVehicle(vehicle)
  }

  _activate(vehicle){
    Backend.activateVehicle(vehicle)
    this.props.onActivatedVehicle(vehicle)
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Vehicle ID',
        id: 'id',
        accessor: call => formatTableID(call.id)
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: vehicle => moment(vehicle.created_at).format('DD/MM/YYYY')
      },
      {
        Header: 'Template',
        id: "model.name",
        accessor: vehicle => {
          return `${vehicle.model.manufacturer.name} ${vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'registration_number'
      },      
      {
        Header: 'Price',
        id: 'price',
        sortable: false,
        accessor: vehicle => {
          return vehicle.price ? `${formatCurrency(vehicle.price / 100)}` : "-"
        }
      },
      {
        Header: 'Mileage',
        id: 'mileage',
        sortable: false,
        accessor: vehicle => {
          const mileage = formatMiles(vehicle)
          return formatNumber(mileage);
        }
      },
      {
        Header: 'Year',
        accessor: 'year'
      },
      {
        Header: 'Colour',
        accessor: 'color'
      },
      {
        Header: "Stats",
        sortable: false,
        Cell: rowInfo => {
          return (
            <button 
              className="btn btn-sm btn-primary btn-block" 
              onClick={() => this.props.viewVehicle(rowInfo.original)}>
              View
            </button>
          )
        }
      },
      {
        id: "status",
        Header: 'Status',
        Cell: rowInfo => {
          return (
            <VehicleStatusCell
              vehicle={rowInfo.original}
              onEdited={vehicle => Backend.editVehicleStatus(vehicle)}
            />
          )
        }
      },
    ]

    return columns
  }

  render() {
    let {
      endpoint
    } = this.state

    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={endpoint}
          columns={columns}
          searchButton={true}
          placeholder="Search"
          showLabel={false}
          defaultSorted={[
            {
              id: "id",
              desc: true
            }
          ]}
        />
      </div>
    )
  }
}

VehiclesTable.defaultProps = {
  dealerMode: true,
  viewVehicle: () => {}
}
