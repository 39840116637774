import React from "react"
import moment from 'moment'
import BaseTable from "./BaseTable"
import AuthManager from '../../../utils/AuthManager'
import { formatCurrency, formatTableID } from '../../../utils/Formatter';

export default class MapLeadsTable extends React.Component {

  constructor(props){
    super(props)

    let endpoint = `${window.Api.Track}?track_type=get_directions`
    this.state = {
      endpoint
    }
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Map ID',
        id: 'id',
        accessor: lead => formatTableID(lead.id * 49)
      },
      {
        Header: 'Date, Time',
        id: 'created_at',
        accessor: vehicle => moment(vehicle.created_at)
          .calendar(null, {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'DD/MM/YYYY, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'DD/MM/YYYY, HH:mm',
            sameElse: 'DD/MM/YYYY, HH:mm'
          })
      },
      {
        Header: 'Vehicle',
        id: "vehicle.model.name",
        accessor: lead => {
          return `${lead.vehicle.model.manufacturer.name} ${lead.vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'vehicle.registration_number'
      },
      {
        Header: 'Customer',
        id: 'customer.user.first_name',
        accessor: lead => {
          if (lead.customer && lead.customer.user)
            return `${lead.customer.user.first_name} ${lead.customer.user.last_name}`
          return ""
        }
      },
      {
        Header: 'Customer Location',
        id: 'customer.county',
        accessor: lead => {
          if (lead.customer && lead.customer.county) 
            return lead.customer.county;
          return "";
        }
      }
    ]

    return columns
  }

  render() {
    let {
      endpoint
    } = this.state

    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          endpoint={endpoint}
          columns={columns}
          searchButton={true}
          placeholder="Search"
          showLabel={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
        />
      </div>
    )
  }
}