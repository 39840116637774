import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import moment from 'moment'

import { formatCurrency, formatMiles, formatNumber } from '../../../utils/Formatter';
import { pmt } from '../../../utils/pmt-calc';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'stretch',
    padding: 40,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    margin: 0,
  },
  logo: {
    height: 35,
    width: 157,
  },
  title: {
    fontfamily: 'Roboto',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 700,
    marginTop: 17,
    marginBottom: 17,
  },
  metadata: {
    fontfamily: 'Roboto',
    fontSize: 14,
    lineHeight: 1.12,
    fontWeight: 700,
  },
  metadataWrapper: {
    marginVertical: 7
  },
  note: {
    fontfamily: 'Roboto',
    fontSize: 10,
    lineHeight: 1.2,
    fontStyle: 'italic',
    marginBottom: 20
  },
  infoHeader: {
    fontSize: 14,
    fontWeight: 700,
    marginVertical: 15
  },
  infoRow: {
    fontSize: 14,
  },
  infoLabel: {
    color: '#707070'
  },
  infoValue: {
    fontWeight: 700,
    textAlign: 'right',
    alignItems: 'flex-end'
  }
});

Font.register({ 
  family: 'Roboto', 
  fonts: [
    { src: '/fonts/Roboto/Roboto-Thin.ttf', fontWeight: 100 },
    { src: '/fonts/Roboto/Roboto-Light.ttf', fontWeight: 300 },
    { src: '/fonts/Roboto/Roboto-Regular.ttf'},
    { src: '/fonts/Roboto/Roboto-Bold.ttf', fontWeight: 700 },
    { src: '/fonts/Roboto/Roboto-Italic.ttf', fontStyle: 'italic'},
  ]
})

// Create Document Component
const FinanceApplicationPDF = ({data}) => {
  const mileage = formatMiles(data.vehicle);

  const information = [{
    section: 'Personal Information',
    data: []
  }, {
    section: 'Employment Information',
    data: []
  }, {
    section: 'Loan Details',
    data: []
  }];

  data.data.answers.forEach( info => {
    switch(info.field.section) {
      case 1: 
        information[0].data.push(info);
        break;
      
      case 2: 
        information[1].data.push(info);
        break;

      default: 
        information[2].data.push(info);
        break;
    }
  })

  return (
    <Document 
      title={`Finance Application for ${data.vehicle.model.manufacturer.name} ${data.vehicle.model.name}`} 
      subject="Finance Application"
      author="Sweep"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <View style={styles.column}>
            <Image 
              style={styles.logo}
              src="/assets/images/logo.png"
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.column}>
            <Text style={styles.title}>New Finance Application</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, styles.metadataWrapper]}>
            {data.vehicle && <Text style={styles.metadata}>To: {data.vehicle.dealer.name}</Text>}
            {data.customer && <Text style={styles.metadata}>From: {data.customer.user.first_name} {data.customer.user.last_name}</Text>}
            {data.customer && <Text style={styles.metadata}>Email: {data.customer.user.email}</Text>}
            <Text style={styles.metadata}>Date: {moment(data.created_at).format('DD/MM/YYYY, HH:MM')}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, styles.metadataWrapper]}>
            {data.vehicle && <Text style={styles.metadata}>Make: {data.vehicle.model.manufacturer.name}</Text>}
            {data.vehicle && <Text style={styles.metadata}>Model: {data.vehicle.model.name}</Text>}
            {data.vehicle && <Text style={styles.metadata}>Year: {data.vehicle.year}</Text>}
            {data.vehicle && <Text style={styles.metadata}>Mileage: {formatNumber(mileage)}</Text>}
            {data.vehicle && <Text style={styles.metadata}>Total Price: {formatCurrency(data.vehicle.price / 100)}</Text>}
            <Text style={styles.metadata}>Monthly Guide Price: {formatCurrency(pmt((data.vehicle.price /100) * 0.8))}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column]}>
            <Text style={styles.note}>* Monthly Prices are for guidance purposes only. Calculation based on a 20% deposit and repayments over 60 months at 11.5% APR. Actual repayments may vary.”</Text>
          </View>
        </View>
        {information.map( section => (
          section.data.length > 0 && (
            <>
              <View style={[styles.row, styles.info]}>
                <View style={[styles.column]}>
                  <Text style={styles.infoHeader}>{section.section}</Text>
                </View>
              </View>  
              {section.data.map( (item, i) => (
                <View key={i} style={[styles.row, styles.infoRow]}>
                  <View style={[styles.column]}>
                    <Text style={styles.infoLabel}>{item.field.title}</Text>
                  </View>
                  <View style={[styles.column]}>
                    <Text style={styles.infoValue}>{item.value}</Text>
                  </View>
                </View>
              ))}
            </>
          )
        ))}
      </Page>
    </Document>
  );

}

export default FinanceApplicationPDF;