import React from "react";
import * as moment from 'moment';

export default class Footer extends React.Component {

  render() {
    return (
      <div className="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
				<div className="kt-footer__copyright">
					{moment().year()} &copy; sweep
				</div>
			</div>
    )
  }
}
