
export default class AsyncStorage {
  static setItem(key, value) {
      return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
      });
  }
  static getItem(key) {
      return Promise.resolve().then(function () {
          return localStorage.getItem(key);
      });
  }
  static removeItem(key) {
      return Promise.resolve().then(function () {
          return localStorage.removeItem(key);
      });
  }
}
