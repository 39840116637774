import React from "react"

import StatCard from './components/cards/StatCard'
import Card from './components/cards/Card'
import VehiclesTable from './components/tables/VehiclesTable'

import Backend from '../utils/Backend'

import ListingDetailsModal from './components/modal/ListingDetails'
import { SpinnerContext } from '../components/Spinner';

export default class Vehicles extends React.Component {
  static contextType = SpinnerContext;
  constructor(props){
    super(props)

    document.title = "Sweep Dealer Dashboard - Listings";

    this.state = {
      loading: true,
      searchTerm: '',
      statusFilters: [],
      showDetails: false, 
      detailedStats: null
    }
  }

  componentDidMount(){
    this._loadStats()
  }

  _loadStats(){
    this.context.toggleSpinner(true)
    Backend.getStats()
      .then(stats => {

        this.setState({
          stats,
          loading: false
        });
        this.context.toggleSpinner(false)
      })
      .catch(e => {
        this.context.toggleSpinner(true)
      }) 

  }

  vehicleClickHandler(rowInfo) {
    Backend.getDetailedStats(rowInfo.id)
      .then(detailedStats => {
        this.setState({
          showDetails: true,
          info: {            
            ...detailedStats,
            ...rowInfo
          },
        })
      });
  }
  render() {
    let {
      stats,
      loading,
      dealerMode
    } = this.state

    if(loading){
      return null
    }

    return (
      <div>
        <div className="row">
          <StatCard
            title="Listings Live on Sweep"
            value={ stats.active_no }
            size="col-md-3 col-lg-3 col-xl-3"
          />
  			</div>
        <div className="row">
          <Card
            title="Vehicles"
            className="col-xl-12"
          >
            <VehiclesTable
              onDeactivatedVehicle={() => {
                stats.active_no -= 1
                this.setState({ stats })
              }}
              onActivatedVehicle={() => {
                stats.active_no += 1
                this.setState({ stats })
              }}
              viewVehicle={this.vehicleClickHandler.bind(this)}
            />
          </Card>
        </div>
        {this.state.info && (
          <ListingDetailsModal 
            show={this.state.showDetails} 
            onHide={() => {
              this.setState({ 
                showDetails: false, 
                info: null
              })
            }}
            info={this.state.info}
          >
          </ListingDetailsModal>
        )}
      </div>
    )
  }
}

Vehicles.defaultProps = {
  dealerMode: true
}
