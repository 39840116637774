import React from "react"

import { CSVLink } from "react-csv";

import ReactTable from "react-table"
import "react-table/react-table.css"

import moment from "moment"

import Backend from '../../../utils/Backend'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'
import "./BaseTable.css";
import { SpinnerContext } from '../../../components/Spinner';
import { InputGroup, FormControl, Button } from "react-bootstrap"

export default class BaseTable extends React.Component {

  static contextType = SpinnerContext;

  constructor(props){
    super(props)

    this.state = {
      loading: true,
      data: [],
      pagesNo: 0,
      minRows: 2,
      searchTerm: "",
      csvData: [],
      count: 0
    }

    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  _handleSearch = General.debounce(() => {
    let current = this.reactTable.current
    if(current){
      current.state.page = 0;
      this._fetchData(current.state, current, 0)
    }
  }, 500)

  _refresh = () => {
    let current = this.reactTable.current
    if (current) {
      this._fetchData(current.state, current, 0)
    }
  }

  _getUrl(endpoint, state){

    let params = {
      ...this.props.params,
      page_size: state.pageSize,
      page: state.page + 1,
      pagination_type: "page",
      search_term: this.state.searchTerm
    }

    let sorted = state.sorted[0]
    if(sorted){
      let orderBy = sorted.id
      orderBy = orderBy.replace(/\./g, "__")
      if(sorted.desc){
        orderBy = `-${orderBy}`
      }
      params["order_by"] = orderBy
    }

    return this._addParams(endpoint, params)
  }

  _fetchData(state, instance) {
    this.setState({ loading: true });
    let url = this._getUrl(this.props.endpoint, state)

    this.context.toggleSpinner(true)
    FetchHelper.get(url)
      .then(response => {
        let minRows = 0;
        if (response.results.length === 0) minRows = 2;

        this.setState({
          data: response.results,
          pagesNo: Math.ceil(response.count / state.pageSize),
          loading: false,
          minRows: minRows,
          count: response.count
        });

        this.context.toggleSpinner(false);
      })
      .catch( () => {
        this.context.toggleSpinner(false);
      });
  }

  _addParams(url, params){
    if(Object.keys(params).length == 0){
      return url
    }

    // TODO: switch to an actual url helper here to avoid bugs/edge cases
    if(url.indexOf("?") == -1){
      url += "?"
    }
    else if(!url.endsWith("&")){
      url += "&"
    }

    Object.keys(params).forEach(function(key) {
        url += key+"="+params[key]+"&";
    });

    // remove last '&'
    url = url.slice(0, -1);
    return url
  }

  _renderExportButtons(){
    if(this.reactTable == null || this.reactTable.current == null || this.props.getCSVData == null){
      return null
    }

    let csvData = this.state.csvData
    let current = this.reactTable.current

    return (
      <div class="row">
        <div className="col-md-12 text-right">
          <a
            className="btn btn-secondary"
            onClick={() => {
              let sortedData = current.getResolvedState().sortedData;
              let csvData = this.props.getCSVData(sortedData);
              this.setState({
                csvData
              }, () => {
                 // click the CSVLink component to trigger the CSV download
                 this.csvLink.current.link.click()
              })
          }}>
            Export to CSV
          </a>
          <CSVLink
            data={csvData}
            filename="data.csv"
            className="hidden"
            ref={this.csvLink}
            target="_blank"
          />
        </div>
      </div>
    )
  }

  render() {
    const { data, pagesNo, loading, count } = this.state;
    const { searchButton } = this.props;

    return (
      <div>
        <div className="row base-table">
          <div className="col-md-6">
            <InputGroup size="sm" className="mb-3">
              <FormControl
                placeholder={this.props.placeholder}
                aria-label={this.props.placeholder}
                className="mr-2"
                onChange={e => {
                  const searchTerm = e.target.value;
                  this.setState({
                    searchTerm
                  }, () => this._handleSearch())
                }}
                style={{ fontSize: '16px'}}
              />
              <Button variant="primary" onClick={this._handleSearch} style={{ width: '104px'}}>Search</Button>
            </InputGroup>
          </div>
          <div className="col-md-6 pull-right">
            { this._renderExportButtons() }
          </div>
        </div>
        <ReactTable
          {...this.props}
          className="-striped"
          ref={this.reactTable}
          manual
          data={data}
          count={count}
          pages={pagesNo}
          loading={loading}
          minRows={this.state.minRows}
          defaultSorted={this.props.defaultSorted}
          onFetchData={this._fetchData.bind(this)}
          columns={this.props.columns}
          loading={this.props.isLoading}
          getTdProps={this.props.getTdProps}
          getTrProps={this.props.getTrProps}
          getTheadProps={this.props.getTheadProps}
          getTheadThProps={this.props.getTheadThProps}
          showPaginationTop={this.props.showPaginationTop}
          showPaginationBottom={this.props.showPaginationBottom}
        />
      </div>
    )
  }
}

BaseTable.defaultProps = {
  params: {},
  label: "Search:",
  showPaginationTop: true,
  showPaginationBottom: true,
  showLabel: true,
  getTdProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingLeft: 10
      }
    }
  },
  getTrProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        paddingTop: 10,
        paddingBottom: 10
      }
    }
  },
  getTheadProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        boxShadow: '0 1px 1px 0 rgba(0,0,0,0.1)',
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'left'
      }
    }
  },
  getTheadThProps: (state, rowInfo, column, instance) => {
    return {
      style: {
        textAlign: 'left',
        paddingLeft: 10
      }
    }
  }
}
