import React from "react"

import Card from '../cards/Card'
import Div from '../layouts/Div'
import Backend from '../../../utils/Backend'

export default class StaffDetail extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    const addStaff = props.staff.user.id == null

    return {
      addStaff: addStaff,
      dealer: props.dealer,
      staff: props.staff || {},
      updateButtonTitle: addStaff ? "Add Staff" : "Update Staff"
    }
  }

  _updateStaffData(key, value){
    let {
      staff
    } = this.state;

    staff.user[key] = value

    this.setState({ staff })
  }

  _isValid(staff){
    let {
      addStaff
    } = this.state
    let error = null;

    let user = staff.user
    if (!user.first_name){
      error = "You must include a first name"
    }
    else if (!user.last_name){
      error = "You must include a last name"
    }
    else if (!user.email){
      error = "You must include an email"
    }
    else if (addStaff && !user.password){
      error = "You must include password"
    }

    if(error){
      alert(error);
      return false;
    }

    return true
  }

  _handleSave(){
    let {
      dealer,
      addStaff,
      staff,
    } = this.state

    if(!this._isValid(staff)){
      return
    }

    this.setState({loading: true})
    this._save(dealer, staff, addStaff)
    .then(() => {
      this.setState({loading: false})
    })
    .catch(error => {
      alert(error.message)
      this.setState({loading: false})
    })
  }

  _save(dealer, staff, addStaff){
    if(addStaff){
      return Backend.createStaff(dealer, staff)
      .then(staff => {
        this.props.onAdded(staff)
      })
    }
    else{
      return Backend.editStaff(staff)
    }
  }

  render() {

    let {
      staff,
      addStaff
    } = this.state

    let {
      canEdit
    } = this.props

    let user = staff.user
    let title = addStaff ? "Add A Member" : user.first_name + " " + user.last_name
    return (
			<Div className="kt-portlet" disabled={this.state.loading}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              { title }
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="form-group">
            <input type="text"
              className="form-control"
              value={user.first_name}
              disabled={!canEdit}
              onChange={e => this._updateStaffData("first_name", e.target.value)}
              placeholder="First Name"
            /><br/>
            <input
              type="text"
              className="form-control"
              disabled={!canEdit}
              value={user.last_name}
              onChange={e => this._updateStaffData("last_name", e.target.value)}
              placeholder="Last Name"
            /><br/>
            <input
              type="text"
              className="form-control"
              value={user.email}
              onChange={e => this._updateStaffData("email", e.target.value)}
              placeholder="Email"
              disabled={!this.state.addStaff}
            /><br/>
            { canEdit &&
              <input
                type="password"
                className="form-control"
                value={user.password}
                onChange={e => this._updateStaffData("password", e.target.value)}
                placeholder="Password"
              />
            }
            { ( !addStaff && canEdit) &&
              <span>If specified, will update the current password</span>
            }
          </div>
        </div>
        { canEdit &&
          <div className="kt-portlet__foot">
            <div className="kt-form__actions">
              <button
                type="reset"
                onClick={() => this._handleSave()}
                className="btn btn-primary">
                  {this.state.updateButtonTitle}
              </button>
            </div>
          </div>
        }
			</Div>
    )
  }
}

StaffDetail.defaultProps = {
  canEdit: false
}
