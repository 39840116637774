import React from 'react'

export default class ConversationActionCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      conversation: props.conversation,
      type: props.type || 'primary'
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderChat(conversation){
    // todo add unread_no?
    return (
      <button 
        className={`btn block btn-block btn-${this.state.type} btn-sm btn-bold`}
        onClick={e => {
          e.preventDefault()
          this.props.onChatClick(conversation)
        }}
      >
        Chat
      </button>
    )
  }

  render() {
    let { conversation } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderChat(conversation) }
      </span>
    )
  }
}
