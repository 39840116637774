import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static getDealer(id){
    return FetchHelper.get(`${window.Api.Dealers}/${id}`)
  }

  static editDealer(dealer){
    return FetchHelper.patch(`${window.Api.Dealers}/${dealer.id}`, dealer)
  }

  static editStaff(staff){
    return FetchHelper.patch(`${window.Api.Staff}/${staff.user.id}`, staff)
  }

  static createStaff(dealer, staff){
    let data = staff
    data.dealer = dealer.id
    return FetchHelper.post(`${window.Api.Staff}`, data)
  }

  static activateVehicle(vehicle){
    return Backend.editVehicle({ id: vehicle.id, active: true })
  }

  static deactivateVehicle(vehicle){
    return Backend.editVehicle({ id: vehicle.id, active: false })
  }

  static editVehicleStatus({ id, status }){
    return Backend.editVehicle({ id, status })
  }

  static editVehicle(vehicle){
    return FetchHelper.patch(`${window.Api.Vehicles}/${vehicle.id}`, vehicle)
  }

  static getLatestVehicles(){
    return FetchHelper.get(`${window.Api.Vehicles}?order_by=-created_at&page_size=8&dealer_id=${AuthManager.currentUser.dealer.id}`)
    .then(response => {
      return response.results
    })
  }

  static getStats(params){
    if(params){
      return FetchHelper.get(`${window.Api.Stats}?${params}`)
    }
    else
    {
      return FetchHelper.get(`${window.Api.Stats}`)
    }
    
  }

  static getDetailedStats(id, filterDate) {
    const params = filterDate ? `?filter_date=${filterDate}` : '';
    return FetchHelper.get(`${window.Api.Vehicles}/${id}/statistics${params}`)
  }

  static uploadImage(image){
    let data = new FormData()
    data.append("file", image, image.name)
    return FetchHelper.post(window.Api.Images, data, true)
  }

  static getConversation(id){
    return FetchHelper.get(`${window.Api.Conversations}/${id}`)
  }

  static editConversation(converation){
    return FetchHelper.patch(`${window.Api.Conversations}/${converation.id}`, converation)
  }

  static getMessages(conversation, nextPageUrl){
    let url = `${window.Api.Messages}?conversation_id=${conversation.id}`
    if(nextPageUrl){
      url = nextPageUrl
    }
    return FetchHelper.get(url)
    .then(response => {
      let messages = response.results.filter(message => message.sender.role !== "bot")
      return {
        messages: messages,
        nextPageUrl: response.next
      }
    })
  }

  static sendMessage(conversation, text){
    let data = {
      text,
      conversation: conversation.id
    }
    return FetchHelper.post(window.Api.Messages, data)
  }

  static markRead(message){
    let data = {
      read: true
    }
    return FetchHelper.patch(`${window.Api.Messages}/${message.id}`, data)
  }

  static registerWebPush(data){
    if(data.browser == "NETSCAPE"){
      data.browser = "CHROME"
    }
    return FetchHelper.post(window.Api.User.WebPush, data)
  }

  static getCalls(){
    return FetchHelper.get(`${window.Api.Calls}`)
  }

  static fetchDealerInfo() {
    return FetchHelper.get(window.Api.User.Info)
  }

  static getFinanceApplication(id){
    return FetchHelper.get(`${window.Api.FinanceApplications}/${id}`)
  }

  static changeApplicationStatus(id, status) {
    return FetchHelper.patch(`${window.Api.FinanceApplications}/${id}`, {
      finance_application_id: id,
      status: status
    })
  }

  static messageToCustomer({ message_type, customer_name, dealer_id, customer_id, vehicle_id, lead_id }) {
    return FetchHelper.post(window.Api.MessageToCustomer, { 
      message_type, customer_name, dealer_id, customer_id, vehicle_id, lead_id 
    })
  }

  static getChatImpressions(vehicle_id, user_id) {
    return FetchHelper.get(`${window.Api.WarmLeads}/vehicle/${vehicle_id}?user_id=${user_id}`)
  }
}
