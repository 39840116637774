import React from 'react'
import "./VehicleStatusCell.css";
export default class VehicleStatusCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      vehicle: props.vehicle
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderStatus(vehicle){
    return (
      <select
        className={"form-control m-t-0 b-space " + vehicle.status}
        value={vehicle.status}
        onChange={e => {
          vehicle.status = e.target.value
          this.setState({ vehicle })
          this.props.onEdited(vehicle)
        }}
      >
       <option value="for_sale">For Sale</option>
       <option value="sold">Sold</option>
       <option value="withdrawn">Withdrawn</option>
      </select>
    )
  }

  render() {
    let { vehicle } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderStatus(vehicle) }
      </span>
    )
  }
}
