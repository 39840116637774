import React from 'react'
import "./ConversationStatusCell.css";
export default class ConversationStatusCell extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      status: props.status || 'open'
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderStatus(status){
    return (
      <select
        className={"form-control m-t-0 b-space " + status}
        value={status}
        onChange={e => {
          const status = e.target.value
          this.setState({ status })
          this.props.onEdited(status)
        }}
      >
       <option value="open">Open</option>
       <option value="closed">Closed</option>
      </select>
    )
  }

  render() {
    let { status } = this.state
    return (
      <span style={{overflow: 'visible', position: 'relative', width: '110px'}}>
        { this._renderStatus(status) }
      </span>
    )
  }
}

ConversationStatusCell.defaultProps = {
  status: 'open'
}
