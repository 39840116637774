import React from "react"

import moment from 'moment'

import BaseTable from "./BaseTable"

import ConversationActionCell from "./cells/ConversationActionCell"
import ConversationStatusCell from "./cells/ConversationStatusCell"

import ChatPortal from "../chat/ChatPortal"

import FinanceApplicationModal from '../modal/FinanceApplication'

import AuthManager from '../../../utils/AuthManager'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'

import { formatCurrency, formatTableID } from '../../../utils/Formatter';

export default class ConversationsTable extends React.Component {
  state = {
    dealer: null,
    showApplicationPrint: false,
    selectedApplication: null,

    showChat: false,
    selectedConversation: null
  }
  

  componentDidMount() {
    this.fetchDealer();
  }

  fetchDealer() {
    Backend.fetchDealerInfo().then(info => {
      this.setState( {
        dealer: info.dealer_staff.dealer
      });
    });
  }

  showChat(conversation){
    this.setState({
      showChat: true,
      selectedConversation: conversation
    })
  }
  hideChat() {
    this.setState({
      showChat: false,
      selectedConversation: null
    });
  }

  openFinanceApp(finance_id) {
    Backend.getFinanceApplication(finance_id)
    .then((data) => {
      this.hideChat();
      
      data.vehicle.dealer = this.state.dealer;
      this.setState({ 
        selectedApplication: data,
        showApplicationPrint: true 
      })
    }).catch(e => alert(e))
  }

  _renderChat() {
    let {
      showChat,
      selectedConversation
    } = this.state

    if(!showChat){
      return null
    }

    const user = selectedConversation.initiator
    const dealer = selectedConversation.vehicle.dealer;
    const vehicle = selectedConversation.vehicle;

    return (
      <ChatPortal 
        openFinanceApp={ this.openFinanceApp.bind(this) }
        conversationId={selectedConversation.id}
        user={user} dealer={dealer} vehicle={vehicle}
        hideChat={() => this.hideChat()}
      />
    )
  }

  _getColumns() {
    let columns = [
      {
        Header: 'Chat ID',
        id: 'id',
        accessor: chat => formatTableID(chat.id * 49)
      },
      {
        Header: 'Customer',
        id: "initiator.first_name",
        accessor: conversation => {
          return `${conversation.initiator.first_name} ${conversation.initiator.last_name}`
        }
      },
      {
        Header: 'Phone',
        id: "initiator.phone_number",
        accessor: conversation => {
          return conversation.initiator.phone_number
        }
      },

      {
        Header: 'Vehicle',
        id: "vehicle.model.name",
        accessor: conversation => {
          return `${conversation.vehicle.model.manufacturer.name} ${conversation.vehicle.model.name}`
        }
      },
      {
        Header: 'Reg',
        accessor: 'vehicle.registration_number'
      },
      {
        Header: 'Price',
        id: 'price',
        sortable: false,
        accessor: conversation => {
          return conversation.vehicle.price ? `${formatCurrency(conversation.vehicle.price / 100)}` : "-"
        }
      },

      {
        Header: 'WhatsApp?',
        id: "initiator.whatsapp_enabled",
        Cell: rowInfo => {
          const conversation = rowInfo.original;
          const hasWhatsapp = conversation.channel === 'whatsapp';
          return <span className={ hasWhatsapp ? 'primary-txt' : 'secondary-txt' }>{ hasWhatsapp ? 'Yes' : 'No' }</span>
        }
      },
  
      {
        Header: 'Last Message',
        id: "last_message.created_at",
        accessor: conversation => {
          return conversation.last_message ? moment(conversation.last_message.created_at).calendar(null, {
            sameDay: '[Today], HH:mm',
            nextDay: '[Tomorrow], HH:mm',
            nextWeek: 'DD/MM/YYYY, HH:mm',
            lastDay: '[Yesterday], HH:mm',
            lastWeek: 'DD/MM/YYYY, HH:mm',
            sameElse: 'DD/MM/YYYY, HH:mm'
          }) : "-"
        }
      },
      {
        id: "status",
        Header: 'Status',
        Cell: rowInfo => {
          // return <div></div>
          return (
            <ConversationStatusCell
              status={rowInfo.original.status}
              onEdited={status => {
                const { id, initiator, vehicle } = rowInfo.original;
                Backend.editConversation({
                  id,
                  status,
                  initiator: initiator.id,
                  vehicle: vehicle.id,
                })
              }}
            />
          )
        }
      },
      {
        id: "actions",
        Header: 'Actions',
        sortable: false,
        Cell: rowInfo => {
          return (
            <ConversationActionCell
              conversation={rowInfo.original}
              onChatClick={conversation => this.showChat(conversation)}
            />
          )
        }
      }
    ]

    return columns
  }

  render() {
    const columns = this._getColumns();

    const { showApplicationPrint, selectedApplication } = this.state;

    return (
      <div>
        <BaseTable
          searchButton={true}
          endpoint={window.Api.Conversations}
          columns={columns}
          placeholder="Search"
          defaultSorted={[
            {
              id: "last_message.created_at",
              desc: true
            }
          ]}
        />

        { showApplicationPrint && 
          <FinanceApplicationModal 
            data={selectedApplication}
            show={showApplicationPrint} 
            onHide={() => {
              this.setState({ 
                selectedApplication: null,
                showApplicationPrint: false
              })
            }}
          />
        }

        { this._renderChat() }
      </div>
    )
  }
}

ConversationsTable.defaultProps = {
  dealerMode: true
}
