import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import LeftMenu from './components/LeftMenu';
import MobileHeader from './components/MobileHeader';
import Footer from './components/Footer';
import Header from './components/Header';
import SubHeader from './components/SubHeader';
import Backend from '../utils/Backend'

import AuthManager from '../utils/AuthManager';

import PushNotifications from '../PushNotifications';

import ChatPortal from './components/ChatPortal';

import Overview from './Overview';
import Settings from './Settings';
import Staff from './Staff';
import Messages from './Messages';
import CallBackRequests from './CallBackRequests';
import Vehicles from './Vehicles';
import PhoneCalls from './PhoneCalls';
import MapLeads from './MapLeads';
import TestDriveRequests from './TestDriveRequests';
import WebsiteLeads from './WebsiteLeads';
import FinanceApplications from './FinanceApplications'
import { ROUTES } from '../constants/Routes';

import Spinner from '../components/Spinner';

import './App.css';



export default class App extends React.Component {
  state = {
    dealer: null
  }
  constructor(props){
    super(props)
    this._loadDealer();
    try {
      PushNotifications.register(AuthManager.currentUser.user)
    }
    catch {
      // most likely IE, do nothing
    }
    
  }

  _loadDealer(){
    Backend.getDealer(AuthManager.currentDealer.id)
      .then(dealer => {
        this.setState({ dealer });
      })
  }
  render() {
    const { dealer } = this.state;
    if(!dealer) {
      return <></>;
    }

    return (
      <div>
        <MobileHeader />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <LeftMenu dealer={dealer} />
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <SubHeader />

                {/* begin:: Content */}
                <div
                  className="kt-content  kt-grid__item kt-grid__item--fluid"
                  id="kt_content"
                  style={{ position: 'relative'}}
                >
                  <Spinner>
                    <Switch>
                      <Route exact path={ROUTES.Dashboard} component={Overview} />
                      <Route exact path={ROUTES.CallBackRequests} component={CallBackRequests} />
                      <Route exact path={ROUTES.Chats} component={Messages} />
                      <Route exact path={ROUTES.PhoneCalls} component={PhoneCalls} />
                      <Route exact path={ROUTES.FinanceApplications} component={FinanceApplications} />
                      <Route exact path={ROUTES.MapLeads} component={MapLeads} />
                      <Route exact path={ROUTES.TestDriveRequests} component={TestDriveRequests} />
                      <Route exact path={ROUTES.WebsiteLeads} component={WebsiteLeads} />
                      <Route exact path={ROUTES.Listings} component={Vehicles} />
                      <Route exact path={ROUTES.Dealership} component={Settings} />
                      <Route exact path={ROUTES.Staff} component={Staff} />
                      <Redirect to="/404" />
                    </Switch>
                    {/*  end:: Content  */}
                    <ChatPortal/>
                  </Spinner>
                </div>
                {/*  begin:: Footer  */}
                <Footer />
                {/*  end:: Footer  */}
              </div>
            </div>
          </div>
          {/*  end:: Page  */}
          {/*  begin::Scrolltop  */}
          <div id="kt_scrolltop" className="kt-scrolltop">
            <i className="fa fa-arrow-up" />
          </div>
        </div>
      </div>
    )
  }
}

const PremiumRoute = ({component: Component, isPremium, dealer, ...rest}) => {
  return (
    <Route {...rest} render={props => (
        isPremium ?
            <Component {...props} dealer={dealer}/>
        : <Redirect to="/signin" />
      )}
    />
  );
};
